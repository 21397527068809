import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from "../../components/SectionTitle";
import Portfolio from "../../components/portfolio";
import portfolios from '../../data/portfolio/index';
import { productC } from '../../data/api';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './style.scss'
import Aos from 'aos';
const PersonalPortfolio = () => {

    const [pdList, setPdList] = useState([])
    useEffect(() => {

        const getPList = async () => {
            var p = [];
            const data = await productC();
            // console.log("data", data.data)

            // TODO: This is a temporary fix to remove the product named "Step Treads" from the list of products

            // Delete the product named "Step Treads"
            for (let i = 0; i < data.data.length; i++) {
                if (data.data[i].name === "Step Treads") {
                    data.data.splice(i, 1);
                }
            }


            /*  for (let i = data.data.length - 1; i >= 0; i--) {
 
                 p.push(data.data[i])
 
             } */
            setPdList(data.data)
        }; getPList();

        Aos.init({ duration: 2000 })

    }, []);
    //console.log(pdList)
    return (
        <div className="section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
            {/* //<Container fluid > */}

            <Container fluid>

                <Row data-aos="fade-right">
                    <Col style={{ marginBottom: "30px" }}>
                        <SectionTitle
                            title={'Our Products'}
                        // content={'content'}
                        />
                    </Col>
                </Row>

                <Row data-aos="fade-up" className={'portfolio-column-four justify-content-center  pl-50 pr-50'} >
                    {pdList.slice(0, 9).map((item, index) => (
                        <Col key={index} lg={4} md={6} className={'mb-30 '} >
                            <Portfolio
                                title={item.name}
                                thumb={item.picurl}
                                Id={item.id}
                                variant={'column'}
                            />
                        </Col>
                    ))}
                </Row>

            </Container>
        </div>
    );
};

export default PersonalPortfolio;