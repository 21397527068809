import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import socialIcons from '../../../data/SocialNetworks'
import LogoTiles from '../../../assets/images/logowht.PNG'
import "./style.scss"
const FooterThree = ({ position }) => {
    return (
        <div className={`footer-section section position-${position} pt-30 pb-30`}>

            <Container fluid className={'align-items-center'}>


                <Row className={'align-items-center'}>

                    <Col className={'footer-copyright '}>


                        <img className={'footerLogo pt-20 pb-20'} src={LogoTiles} alt="logo" />
                        <div className="details ">

                            <ul>

                                <li>
                                    <span>TEL: 08 8443 9111</span>
                                </li>
                                {/* <li>
                                    <span>FAX: 08 8443 5448</span>
                                </li> */}
                                <li>
                                    <span>EMAIL: sales@tilesonbradman.com.au</span>
                                </li>
                                <li>

                                    <div className="last">
                                        <span>119 Sir Donald Bradman Dr, Hilton, South Australia, 5033, Australia</span>
                                        <div className={'footer-icon col-lg-5 col-12'}>
                                            {socialIcons.map(social => (
                                                <a key={social?.id}
                                                    href={social?.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i className={`fa fa-${social?.media}`} style={{ color: "white" }} />
                                                </a>
                                            ))}
                                        </div></div>

                                </li>

                                <hr style={{ color: 'white', backgroundColor: 'white', height: '5' }} />
                                <li>
                                    <p> &copy; Tiles on Bradman Drive {new Date().getFullYear()} </p>
                                </li>
                            </ul>

                        </div>

                    </Col>
                </Row>

            </Container>
        </div>
    );
};

export default FooterThree;
