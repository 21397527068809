import React, { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import SideHeader from "../../components/SideHeader";
import Header from "../../components/header/HeaderOne";
import FooterThree from "../../components/footer/FooterThree";
import backImage from '../../assets/images/hero/homepage1.jpg'
const ComDetail = () => {

    const detail = JSON.parse(localStorage.getItem("detail"));

    return (

        <Fragment>
            <Header classes={'position-static'} />
            <SideHeader mobile={true} />

            <div className='bg-grey section' style={{ backgroundImage: `url(${backImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xl-10 col-lg-10 col-md-10 mb-5 mb-md-0'>
                            <div className='card contact-form-wrap contact-form-bg d-flex justify-centent-center align-items-center'>
                                <h3 className='d-flex w-75'>Comment Details</h3>


                                {detail.firstname && detail.lastname && (

                                    <div className='rld-single-input  w-75'>
                                        <h4> Name:  </h4>
                                        <h5>{detail.firstname}  {detail.lastname}</h5>
                                    </div>

                                )}
                                {detail.companyname && (

                                    <div className='rld-single-input  w-75'>
                                        <h4>Company Name:  </h4>
                                        <h5>{detail.companyname}</h5>
                                    </div>

                                )}
                                {detail.email && (

                                    <div className='rld-single-input  w-75'>
                                        <h4>Email:  </h4>
                                        <h5>{detail.email}</h5>
                                    </div>

                                )}
                                {detail.phone && (

                                    <div className='rld-single-input  w-75'>
                                        <h4>Phone number:  </h4>
                                        <h5>{detail.phone}</h5>
                                    </div>

                                )}
                                {detail.timestamp && (

                                    <div className='rld-single-input  w-75'>
                                        <h4>Date and Time:  </h4>
                                        <h5>{detail.timestamp}</h5>
                                    </div>

                                )}
                                {detail.content && (

                                    <div className='rld-single-input  w-75'>
                                        <h4>Description:  </h4>
                                        <p>{detail.content}</p>
                                    </div>

                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterThree position={'static'} />
        </Fragment>

    );
};

export default ComDetail;