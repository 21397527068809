import React, { Fragment } from 'react';
import Sidebar from "../../components/sidebar";
import banner from '../../assets/images/banner/banner-1.jpg'
import { Link, useParams } from 'react-router-dom'
import posts from '../../data/blog';

const SidebarWrap = ({ data }) => {
    const handleDetail = (item) => {
        //console.log('aaaa', item)
        localStorage.setItem("details", JSON.stringify(item));

    };
    return (
        <Fragment>
            <Sidebar>
                {/* <div className="sidebar-search">
                    <form action="/" method="post">
                        <input type="text" placeholder="Search" />
                        <button><i className="fa fa-search" /></button>
                    </form>
                </div> */}
            </Sidebar>

            {/* <Sidebar title={"ABOUT ME"}>
                <div className="sidebar-about">
                    <p>Temporary removed</p>
                </div>
            </Sidebar> */}

            <Sidebar title={"Categories"}>

                <ul className="sidebar-list" style={{ paddingLeft: "0" }}>
                    {data.map((item, index) => (
                        <li key={index}>
                            <Link to={`/tile-details/${item.id}`} onClick={() => handleDetail(item)}>{item.tittle}</Link>
                        </li>

                    ))}
                </ul>
            </Sidebar>

            {/* <Sidebar title={"Newest post"}>
                <ul className="sidebar-post">
                     {posts.slice(0, 3).map(post => (
                        <li key={post.id}>
                            <a href={process.env.PUBLIC_URL + "/blog-details"}>{post.title}</a>
                            <span>{post.meta.postDate}</span>
                        </li>
                    ))}
                    <p>Temporary removed</p>
                </ul>
            </Sidebar> */}

            {/*  <Sidebar title={"Tags"}>
                <div className="sidebar-tags">
                     <a href="/">Corporate</a>
                    <a href="/">Graphic Design</a>
                    <a href="/">Photoshop</a>
                    <p>Temporary removed</p>
                </div>
            </Sidebar> */}

            <Sidebar>
                {/*  <div className="sidebar-banner">
                    <a href="/"><img src={banner} alt="banner" /></a>
                </div> */}
            </Sidebar>
        </Fragment>
    );
};

export default SidebarWrap;