import React from 'react';
import { Link } from 'react-router-dom';

const Service = ({ title, content, item }) => {


    const handleDetail = (item) => {
        //console.log("item", item)
        localStorage.setItem("detail", JSON.stringify(item));
    };

    return (
        <div className="service-item" >

            <Link
                to={'/cmDetail'}
                onClick={() => handleDetail(item)}
            >
                {title ? <h3>{title}</h3> : <h3>Company Name:</h3>}
            </Link>

            <p>Message: {content}</p>
        </div>
    );
};

export default Service;