import React from 'react';
import { NavItem } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'

const Blog = ({ data }) => {

    return (
        <div className="blog-item">
            <div>

                {data.pic ? (<Link to={`/tile-details/${data.id}` }  className="blog-image">
                    <img style={{ height: "100%", width:"100%", objectFit: "cover" }} src={data.pic}></img></Link>) : null}
            </div>

            <div><p>{data.tittle}</p></div>



        </div>
    );
};

export default Blog;