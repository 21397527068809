import React, { Fragment } from 'react';
import Switcher from "./components/Switcher";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.scss';
import "antd/dist/antd.css";
import ScrollToTop from "./scrollToTop";

import { Component } from 'react';
//import App from './App';
//import * as serviceWorker from './serviceWorker';


//Home First page
import HomePersonalPortfolio from "./pages/home/HomePersonalPortfolio";
import AboutPage from "./pages/about";
import ErrorPage from "./pages/404Error";
import ContactPage from "./pages/contact";
//comment
import CommentPage from "./pages/service";
//comment details
import ComDetail from './container/Comment/detail';
import BlogThreeColumnPage from "./pages/blog/ThreeColumn"; //product management
//login
import Login from "./pages/user/login";
import Register from "./pages/user/register";
//Shoppinh\g Car
import Shopping from './pages/shopping';
//add new product
import AddNewProduct from './pages/blog/addNewP';
//Management
import ProductManagement from './pages/management/manageProduct';
//product
import BlogTwoColumnLeftSidebarPage from "./pages/blog/TwoColumnLeftSidebar"; //product page
import PortfolioDetailsPage from "./pages/portfolio/details"; //product detail
import PoolPage from './templates/blog/poolPage';

//Home Template
import HomeBlog from "./pages/home/HomeBlog";
import HomeDefault from "./pages/home/HomeDefault";
import HomeFiveColumn from './pages/home/HomeFiveColumn';
import HomeFourColumn from "./pages/home/HomeFourColumn";

import HomeThreeColumn from "./pages/home/HomeThreeColumn";
import TeamPage from "./pages/team";
import HomeFreelancerPortfolio from "./pages/home/HomeFreelancerPortfolio";
//Portfolio
import PortfolioGridTwoColumnPage from "./pages/portfolio/grid/two-column";
import PortfolioGridFourColumnPage from "./pages/portfolio/grid/four-column";
import PortfolioGridThreeColumnPage from "./pages/portfolio/grid/three-column";
//Blog 
import BlogDetailsLeftSidebarPage from "./pages/blog/BlogDetailsLeftSidebar";
import BlogDetailsWithoutSidebar from "./pages/blog/BlogDetailsWithoutSidebar";
import BlogTwoColumnRightSidebarPage from "./pages/blog/TwoColumnRightSidebar";
import BlogDetailsRightSidebarPage from "./pages/blog/BlogDetailsRightSidebar";

import PortfolioMasonryTwoColumnPage from "./pages/portfolio/masonry/two-column";
import PortfolioMasonryFourColumnPage from "./pages/portfolio/masonry/four-column";
import PortfolioMasonryThreeColumnPage from "./pages/portfolio/masonry/three-column";

import PortfolioGridFourColumnFullWidthPage from "./pages/portfolio/grid/four-column-fullwidth";
import PortfolioGridFiveColumnFullWidthPage from "./pages/portfolio/grid/five-column-fullwidth";
import PortfolioGridThreeColumnFullWidthPage from "./pages/portfolio/grid/three-column-fullwidth";
import PortfolioMasonryFourColumnFullWidthPage from "./pages/portfolio/masonry/four-column-fullwidth";
import PortfolioMasonryFiveColumnFullWidthPage from "./pages/portfolio/masonry/five-column-fullwidth";
import PortfolioMasonryThreeColumnFullWidthPage from "./pages/portfolio/masonry/three-column-fullwidth";

const Root = () => {

    return (

        < Fragment >
            {/*  <Switcher /> */}

            < Router >
                <ScrollToTop />
                <Switch>

                    {/* Home page */}
                    {<Route exact path={`${process.env.PUBLIC_URL + "/"}`}
                        component={HomePersonalPortfolio}
                    />}
                    <Route path={`${process.env.PUBLIC_URL + "/profile"}`}
                        component={HomePersonalPortfolio} />

                    {/* product */}
                    <Route path={`${process.env.PUBLIC_URL + "/product/:id"}`}
                        component={BlogTwoColumnLeftSidebarPage} />

                    <Route path={`${process.env.PUBLIC_URL + "/poolTiles"}`}
                        component={PoolPage} />
                    {/* product details */}
                    <Route path={`${process.env.PUBLIC_URL + "/tile-details/:productId"}`}
                        component={PortfolioDetailsPage} />

                    {/* about */}
                    <Route path={`${process.env.PUBLIC_URL + "/about"}`}
                        component={AboutPage} />

                    {/* contact us */}
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`}
                        component={ContactPage} />

                    {/* login and register */}
                    <Route path={`${process.env.PUBLIC_URL + "/login"}`}
                        component={Login} />
                    <Route path={`${process.env.PUBLIC_URL + "/register"}`}
                        component={Register} />

                    {/*  Shopping Cart */}
                    < Route path={`${process.env.PUBLIC_URL + "/shopCar"}`}
                        component={Shopping} />

                    {/* manager */}
                    {/* comment */}
                    <Route path={`${process.env.PUBLIC_URL + "/comment"}`}
                        component={CommentPage} />
                    {/* comment details */}
                    <Route path={`${process.env.PUBLIC_URL + "/cmDetail"}`}
                        component={ComDetail} />
                    {/* ----------------------------------------------------- */}
                    {/*  Add new product */}
                    <Route path={`${process.env.PUBLIC_URL + "/newproduct"}`}
                        component={AddNewProduct} />
                    {/* ----------------------------------------------------- */}
                    {/*  product management */}
                    <Route path={`${process.env.PUBLIC_URL + "/blog"}`}
                        component={BlogThreeColumnPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/management"}`}
                        component={ProductManagement} />
                    {/*    <Route path={`${process.env.PUBLIC_URL + "/management"}`}
                        component={BlogDetailsRightSidebarPage} /> */}

                    {/* <Route path={`${process.env.PUBLIC_URL + "/team"}`}
                        component={TeamPage} /> */}

                    {/* blog Template */}
                    {/*  <Route path={`${process.env.PUBLIC_URL + "/blog-details-without-sidebar"}`}
                        component={BlogDetailsWithoutSidebar} />
                    <Route path={`${process.env.PUBLIC_URL + "/blog-details-left-sidebar"}`}
                        component={BlogDetailsLeftSidebarPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/product"}`}
                        component={BlogTwoColumnRightSidebarPage} /> */}
                    {/* portfolio Template*/}
                    {/*  <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-five-column-fullwidth"}`}
                        component={PortfolioMasonryFiveColumnFullWidthPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-four-column-fullwidth"}`}
                        component={PortfolioMasonryFourColumnFullWidthPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-three-column-fullwidth"}`}
                        component={PortfolioMasonryThreeColumnFullWidthPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-four-column"}`}
                        component={PortfolioMasonryFourColumnPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-three-column"}`}
                        component={PortfolioMasonryThreeColumnPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-two-column"}`}
                        component={PortfolioMasonryTwoColumnPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-five-column-fullwidth"}`}
                        component={PortfolioGridFiveColumnFullWidthPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-four-column-fullwidth"}`}
                        component={PortfolioGridFourColumnFullWidthPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-three-column-fullwidth"}`}
                        component={PortfolioGridThreeColumnFullWidthPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-four-column"}`}
                        component={PortfolioGridFourColumnPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-three-column"}`}
                        component={PortfolioGridThreeColumnPage} />
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-two-column"}`}
                        component={PortfolioGridTwoColumnPage} /> */}

                    {/* Home Template */}

                    {/* <Route path={`${process.env.PUBLIC_URL + "/home-freelancer-portfolio"}`}
                        component={HomeFreelancerPortfolio} /> */}
                    {/* <Route path={`${process.env.PUBLIC_URL + "/home-blog"}`}
                           component={HomeBlog}/> */}
                    {/* <Route path={`${process.env.PUBLIC_URL + "/home-five-column"}`}
                           component={HomeFiveColumn}/> */}
                    {/* <Route path={`${process.env.PUBLIC_URL + "/home-four-column"}`}
                           component={HomeFourColumn}/> */}
                    {/*  <Route path={`${process.env.PUBLIC_URL + "/home-three-column"}`}
                        component={HomeThreeColumn} /> */}
                    {/* <Route path={`${process.env.PUBLIC_URL + "/home-default"}`}
                        component={HomeDefault} /> */}

                    <Route exact component={ErrorPage} />
                </Switch>
            </Router >
        </Fragment >
    );
};

export default Root;



ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
