import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useEffect, useState } from "react";
import './style.scss';
import { ModalTitle } from "react-bootstrap";

const BigIMageS = ({ show, handleClose, image, picname }) => {

    // console.log("cl", image)
    return (
        <Modal show={show} onHide={handleClose} size="lg" >

            {picname != null && picname != "null" ? <Modal.Header >

                <ModalTitle>{picname}</ModalTitle>


            </Modal.Header> : null}

            <Modal.Body>

                <div >
                    <img src={image} alt="product" style={{ width: "100%" }} />

                </div>
            </Modal.Body>
        </Modal>
    );
};
export default BigIMageS;