import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import navbarData2 from '../../data/navbar/navbar2'
import { FiShoppingCart } from "react-icons/fi";
import { productC } from '../../data/api';
import { classInfo } from '../../data/api';
import { Link } from 'react-router-dom'
const NavItem2 = () => {
    const user = JSON.parse(localStorage.getItem("user" || {}));
    const [pdList, setPdList] = useState([])
    const [pool, setPool] = useState("");

    useEffect(() => {

        const getPList = async () => {

            const data = await productC();

            setPdList(data.data)
        }; getPList();

        const getInfo = async () => {

            const data = await classInfo(6);

            setPool(data.data[0])
        }; getInfo();

    }, []);
    //  console.log(pool)

    return (

        navbarData2.map(navItem => (
            <li key={navItem.id} className={navItem.subMenu ? 'menu-item-has-children' : null}>
                <NavLink
                    exact={navItem.link === `/`}
                    to={`${process.env.PUBLIC_URL + navItem.link}`}

                    activeClassName={'active'}>
                    <a>{navItem.title}</a>
                </NavLink>



                {
                    navItem.list ? (
                        <ul className="sub-menu">
                            {pdList.map((item, index) => (
                                <li key={index}
                                >
                                    <Link to={`/product/${item.id}`}>{item.name}</Link>

                                </li>

                            ))}
                        </ul>
                    ) : null

                }
                {
                    navItem.subMenu && navItem.id != 3 ? (
                        <ul className="sub-menu">
                            {navItem.subMenu.map(subItem => (
                                <li key={subItem.id}
                                    className={subItem.subMenu ? "menu-item-has-children" : null}>
                                    {subItem.id == 6.1 && user[1] == 201 ? <NavLink to={`${process.env.PUBLIC_URL + subItem.link}`}>{subItem.title}</NavLink> : null}
                                    {subItem.id != 6.1 ? <NavLink to={`${process.env.PUBLIC_URL + subItem.link}`}>{subItem.title}</NavLink> : null}
                                    {subItem.subMenu ? (
                                        <ul className="sub-menu">
                                            {subItem.subMenu.map(dropSubItem => (
                                                <li key={dropSubItem.id}>
                                                    <NavLink to={`${process.env.PUBLIC_URL + dropSubItem.link}`}>
                                                        {dropSubItem.title}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : null}
                                </li>
                            ))}
                        </ul>
                    ) : null
                }
            </li >

        ))
    );
};

export default NavItem2;
