import React, { useEffect, useState } from 'react';
import socialIcons from '../../data/SocialNetworks'
import { BsFolder } from "react-icons/bs";
import { getProduct } from '../../data/api';
import './style.scss'
const HeaderSocial = () => {
    var userid;
    const user = JSON.parse(localStorage.getItem("user"));
    const carId = JSON.parse(localStorage.getItem("shopId"));
    const [productN, setProductN] = useState(0);
    if (user === null) {

        userid = 1;
    } else {

        userid = user[0]
    }

    const openSideMenu = () => {
        const sideMenuWrap = document.querySelector(".side-header");
        const overlay = document.querySelector('.side-menu-overlay');
        sideMenuWrap.classList.add('side-menu-open');
        overlay.classList.add('overlay-show');
    };

    useEffect(() => {



        if (carId == null || carId == 0) {

        } else {
            const getCList = async () => {
                const list = await getProduct(userid, carId);

                //  console.log("a", list);
                if (list.ret == "200") {

                    setProductN(list.data.length)


                }

            }; getCList();
        }


    }, []);

    return (
        <div className="header-social d-flex align-items-center justify-content-end col-lg-1 col-6">

            <a className="shopIcon" href="/shopCar">
                < BsFolder />
                <span>
                    {productN}
                </span>
            </a>
            <button
                className="side-header-toggle d-block d-lg-none order-12 ml-10"
                onClick={openSideMenu}
            >
                <span />
            </button>

            {/* {socialIcons.map(social => (
                <a key={social?.id}
                    href={social?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className={`fa fa-${social?.media}`} />
                </a>
            ))} */}
        </div>
    );
};

export default HeaderSocial;
