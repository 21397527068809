import React from 'react';
import Header from "../../components/header/HeaderOne";
import FooterThree from "../../components/footer/FooterThree";
import NewGoods from '../../templates/blog/NewProduct';
import SideHeader from "../../components/SideHeader";

const AddNewProduct = () => {
    return (
        <div>
            <Header classes={'position-static'} />
            <SideHeader mobile={true} />
            <NewGoods
                blogColumnClass={'col-md-6 col-12'}

            />
            <FooterThree position={'static'} />
        </div>
    );
};

export default AddNewProduct;