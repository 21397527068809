import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useEffect, useState } from "react";
import './style.scss';
import { ModalTitle } from "react-bootstrap";
import { favorite } from "../../../data/api";
const Quote = ({ userid, q, hClose, handleCart }) => {

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [postcode, setPostcode] = useState("");
    const [suburb, setSuburb] = useState("");
    const [address, setAddress] = useState("");


    const shopcarid = JSON.parse(localStorage.getItem("shopId"));

    //submit quote form
    const submitForm = async () => {


        if (username != "" && email != "" && postcode != "" && suburb != "" && phone != "") {

            const r = await favorite(
                userid,
                username,
                email,
                phone,
                postcode,
                suburb,
                address,
                shopcarid,
            );
            if (r.ret == 200) {

                handleCart();
                hClose();
            }

        }

    }
    return (
        <Modal show={q} onHide={hClose} backdrop="static" >

            <Modal.Header closeButton>

                <ModalTitle>Request for Quote</ModalTitle>

            </Modal.Header>




            <Modal.Body>
                <div className=" quote ">
                    <input type={'text'} id={'Name'} name={'Name'} placeholder={'Name *'} required onChange={(e) => setUsername(e.target.value)} />
                    <input type={'text'} id={'Address'} name={'Address'} placeholder={'Address *'} required onChange={(e) => setAddress(e.target.value)} />

                    <div className="d-flex justify-content-between">
                        <input style={{ width: "48%" }} type={'text'} id={'Suburb'} name={'Suburb'} placeholder={'Suburb *'} required onChange={(e) => setSuburb(e.target.value)} />
                        <input style={{ width: "48%" }} type={'text'} id={'Postal Code'} name={'Postal Code'} placeholder={'Postal Code *'} required onChange={(e) => setPostcode(e.target.value)} />
                    </div>

                    <input type={'email'} id={'email'} name={'email'} placeholder={'Email *'} required onChange={(e) => setEmail(e.target.value)} />
                    <input type={'mobile'} id={'phone'} name={'phone'} placeholder={'Phone *'} required={true} onChange={(e) => setPhone(e.target.value)} />

                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <button className="qsubmit" onClick={submitForm} >Submit</button>
            </Modal.Footer>


        </Modal>
    );
};
export default Quote;