import React, { Fragment, useState } from 'react';
import Contact from "../../container/contact";
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import FooterThree from "../../components/footer/FooterThree";
import backImage from '../../assets/images/hero/register.jpg'
import Input from "../../components/input";
import { userRegister } from '../../data/api';
import { createBrowserHistory } from "history";
import "./log.scss"
import { notification } from "antd";
import { FrownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const Register = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const history = createBrowserHistory();

    const handleRegister = async () => {

        if (userName, password, email, phone != "") {
            const register = await userRegister(
                userName,
                password,
                phone,
                email
            );
            //console.log(register);
            if (register.ret === 200) {
                history.push("/login");
                window.location.reload();
            } else {
                // console.log(register.ret)
                notification.open({
                    message: 'Error',
                    description: 'This Username has been used!!!  ',
                    placement: 'btmRight',
                    icon: <ExclamationCircleOutlined style={{ color: "red" }} />

                });
                //console.log(register.msg)
            }

        };
    }

    return (
        <Fragment>
            <Header classes={'position-static'} />
            <SideHeader mobile={true} />
            <div className="bg-grey section" style={{ backgroundImage: `url(${backImage})` }}>

                <form>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-xl-5 col-lg-10 col-md-6 mb-5 mb-md-0'>
                                <div className='card contact-form-wrap contact-form-bg d-flex justify-content-center align-items-center'>
                                    <h2 >Register</h2>
                                    <div className=' rld-single-input d-flex justify-content-center align-items-center' >
                                        <input
                                            type={'name'}
                                            placeholder='Username'
                                            onChange={(e) =>
                                                setUserName(e.target.value)
                                            }
                                            required
                                        />
                                    </div>

                                    <div className=' rld-single-input d-flex justify-content-center align-items-center' >
                                        <input
                                            type={'phone'}
                                            placeholder='Phone'
                                            onChange={(e) =>
                                                setPhone(e.target.value)
                                            }

                                        />
                                    </div>
                                    <div className=' rld-single-input d-flex justify-content-center align-items-center' >
                                        <input
                                            type='email'
                                            placeholder='Email'
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            required
                                        />
                                    </div>

                                    <div className='rld-single-input d-flex justify-content-center align-items-center'>
                                        <input
                                            type={'password'}
                                            placeholder='Password'
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }

                                        />
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <button
                                            className='signB'
                                            type={'submit'}

                                            onClick={handleRegister}

                                        >
                                            Register
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <FooterThree position={'static'} />
        </Fragment>
    );
};

export default Register;