import React, { Fragment, useState, useEffect } from 'react';
import Header from "../../../components/header/HeaderOne";
import SideHeader from "../../../components/SideHeader";
import FooterThree from "../../../components/footer/FooterThree";
import TemplatePortfolioDetails from "../../../templates/portfolio/details";
import { goodsBY_id } from "../../../data/api";
import { useParams } from 'react-router-dom';
import Loading from '../../../components/loading';
const PortfolioDetailsPage = () => {
    const { productId } = useParams();

    const [goods, setGoods] = useState();
    const [sImage, setSImage] = useState();
    const [picName, setpicName] = useState();
    const [img, setImg] = useState([]);
    const [gNames, setGNames] = useState([]);
    const [gTitle, setGTitle] = useState();
    useEffect(() => {

        const getGoods = async () => {
            var im = [];
            var name = [];
            const data = await goodsBY_id(productId);

            if (data.ret == 200) {


                setGoods(data.data[0]);
            }

        }; getGoods();


    }, [productId]);


    return (
        <Fragment>
            <Header classes={'position-static'} />
            <SideHeader mobile={true} />
            {goods != null ? <TemplatePortfolioDetails details={goods} /> : <Loading />}
            <FooterThree position='static' />
        </Fragment>
    );
};

export default PortfolioDetailsPage;