import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FiChevronLeft } from "react-icons/fi";
import PageContainer from "../../../container/CustomPage";
import PortfolioDetailsThumb from "./thumbnail";
import PortfolioDetailsContent from "./content";
import portfolioDetailsData from '../../../data/portfolio/details'
import { useHistory } from "react-router-dom";
import './style.scss';
const TemplatePortfolioDetails = ({ details }) => {
    // console.log(details)
    let history = useHistory();
    const [img, setImg] = useState([])
    const [name, setName] = useState(0);
    const [selectedImg, setSelectedImg] = useState(details.pic);
    const [check, setCheck] = useState(true);
    const [picName, setpicName] = useState(details.picname1);
    useEffect(() => {

        const getImage = () => {
            var im = [];
            if (details.pic != null && details.pic != "delete") {
                im.push(details.pic)
            }

            if (details.pic2 != null && details.pic2 != "delete") {
                im.push(details.pic2)
            }
            if (details.pic3 != null && details.pic3 != "delete") {
                im.push(details.pic3)
            }
            if (details.pic4 != null && details.pic4 != "delete") {
                im.push(details.pic4)
            }
            if (details.pic5 != null && details.pic5 != "delete") {
                im.push(details.pic5)
            }
            if (details.pic6 != null && details.pic6 != "delete") {
                im.push(details.pic6)
            }
            if (details.pic7 != null && details.pic7 != "delete") {
                im.push(details.pic7)
            }
            if (details.pic8 != null && details.pic8 != "delete") {
                im.push(details.pic8)
            }
            if (details.pic9 != null && details.pic9 != "delete") {
                im.push(details.pic9)
            }
            if (details.pic10 != null && details.pic10 != "delete") {
                im.push(details.pic10)
            }

            setImg(im);
        }; getImage();


    }, []);

    const handleColor = (e) => {
        if (e == 0) {
            setpicName(details.picname1)
        }
        if (e == 1) {
            setpicName(details.picname2)
        }
        if (e == 2) {
            setpicName(details.picname3)
        }
        if (e == 3) {
            setpicName(details.picname4)
        }
        if (e == 4) {
            setpicName(details.picname5)
        }
        if (e == 5) {
            setpicName(details.picname6)
        }
        if (e == 6) {
            setpicName(details.picname7)
        }
        if (e == 7) {
            setpicName(details.picname8)
        }
        if (e == 8) {
            setpicName(details.picname9)
        }
        if (e == 9) {
            setpicName(details.picname10)
        }

        setSelectedImg(img[e]);
    }

    const handleF = () => {

        setCheck(false);
    }
    const handleT = (e) => {

        setCheck(true);
    }
    const handleName = (e) => {

        setName(e);
    }
    return (
        <div className="section-wrap section pt-1200 pt-md-0 pt-sm-0 pt-xs-0 pb-120 pb-lg-0 pb-md-0 pb-sm-0 pb-xs-0">
            <a style={{ paddingTop: 30, paddingLeft: 30 }}><button className='back_B' onClick={() => history.goBack()}><FiChevronLeft /></button></a>
            <Container>

                <Row>
                    <Col lg={7}>
                        <PortfolioDetailsThumb images={img} details={details} picColor={name} handleName={handleName} handleF={handleF} hanldeColor={handleColor} />
                    </Col>

                    <Col lg={5} className={`${img.length > 6 ? 'mt-70' : ''}`}>
                        <PortfolioDetailsContent
                            data={details}
                            handleName={handleName}
                            picName={picName}
                            selectedImg={selectedImg}
                            handleColor={handleColor}
                        /*  title={portfolioDetailsData.title}
                         category={portfolioDetailsData.category}
                         description={portfolioDetailsData.description}
                         meta={portfolioDetailsData.meta} */
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TemplatePortfolioDetails;