import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useEffect, useState } from "react";
/* import './style.scss'; */
import backImage from '../../../assets/images/hero/homepage1.jpg'
import { ModalTitle } from "react-bootstrap";
import { getProduct } from "../../../data/api";


const HistoryDetail = ({ showF, handleCloseF, detail }) => {

    const [shopCarList, setShopCarList] = useState([]);

    useEffect(() => {

        const getCList = async () => {

            const list = await getProduct(detail.userid, detail.shopcarid);

            // console.log("a", list);
            if (list.ret == "200") {
                setShopCarList(list.data)
            } else {


            }

        }; getCList();

    }, []);


    //console.log(shopCarList)
    return (
        <Modal show={showF} onHide={handleCloseF} /* backdrop="static" */ size="lg"   >

            <Modal.Header closeButton>

                <ModalTitle>Favorite Details</ModalTitle>

            </Modal.Header>
            <Modal.Body className=' d-flex col justify-content-center' style={{ backgroundImage: `url(${backImage})`, objectFit: "cover", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className='comDetail row  pr-20  pb-30 pt-30 w-100' style={{ width: '90%', backgroundColor: 'white' }}>

                    {shopCarList.length > 0 ?
                        <div className='w-100  pt-10 mb-10 '>
                            <h6>ShopCar List:  </h6>
                            {shopCarList.map((item, index) => (
                                <div className='card contact-form-wrap justify-content-between' style={{ padding: "10px", marginBottom: "10px" }}>

                                    <div key={index} className='row '>

                                        <div className="d-flex col-12">

                                            <div className="col-7">
                                                <h6> Item</h6>
                                                <div className='detailP' >
                                                    <img src={item.picurl} />

                                                </div>
                                            </div>
                                            <div style={{ textAlign: 'left', alignContent: 'center' }}>
                                                <h6 > Details</h6>
                                                <div className="detailC" style={{ color: 'black' }}>

                                                    {item.tittle ? <div> <span>Name: {item.tittle}</span></div> : null}
                                                    {item.quantity != "null" ? <div> <span>Color: {item.quantity}</span></div> : null}
                                                    {item.thickness ? <div><span>Thickness: {item.thickness}</span></div> : null}
                                                    {item.size ? <div> <span>Size: {item.size}</span></div> : null}

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            ))}
                        </div> : null}
                </div>


            </Modal.Body>
            <Modal.Footer className="justify-content-center">

            </Modal.Footer>
        </Modal>
    );
};
export default HistoryDetail;