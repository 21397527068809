import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import Thumbnail from "../../components/thumbnail";
import Content from "../../components/content";
import Skill from "../../components/skill";
import aboutData from '../../data/AboutMe'
import picture1 from '../../assets/images/about/p1.jpg';
import picture2 from '../../assets/images/about/Award.jpg';
import picture3 from '../../assets/images/about/picture3.jpg';
import picture4 from '../../assets/images/about/picture4.png';
import './aboutme.scss'
const AboutMe = ({ type }) => {
    return (
        <div className={type !== 'page' ? 'section-padding section' : null}>
            <Container>
                <Row className={'align-items-center'}>
                    <h1 style={{ marginTop: "20px" }}>About US</h1>
                    <div className="pt-10 pb-40">
                        <h3>Our mission</h3>
                        <p className="res">
                            Our mission is to provide our clients with the best possible service and products.
                            We fulfil this aim through stocking the highest quality tiles in a vast range, while keeping up to date with the latest trends. We are the pool tile specialists, but also are known for our high quality Italian tiles – imported directly from Europe. Our staff will provide you with expert service and advice. We will ensure that when you leave our store you will feel great about not only your tiles, but your experience choosing them too.
                        </p>
                    </div>

                    <div>
                        <h3>Our History</h3>

                        <div className="res   pb-40">

                            <img className="container mt-0 mb-0 picture1" src={picture1} alt='p1' style={{ height: '200px', width: '280px' }} />
                            <span className=" pr-10">
                                <p>
                                    The customer has come first every step of the way since 1973. We are one of the longest established tile companies in South Australia. Previously known as Tiles on Burbridge, we have been providing premium tiles and exceptional service in Adelaide’s inner west for decades. Recent years have seen an enormous growth in our range and quality. Our reputation in the industry is highly regarded and the awards we have received over the years are a testament to this.
                                </p>
                                <p>
                                    During our experience of over 40 years we have accumulated a number of awards acknowledging our excellence in the field. Most recently the esteemed Australian Tile Council has recognised our ongoing contribution to the industry, awarding us a certificate of Outstanding Service and Support.
                                </p>
                            </span>

                        </div>

                    </div>


                    <div >
                        <h3>A Personalised Interior Design Service</h3>
                        <div className="res  pb-40">
                            <img className="picture2" src={picture2} alt='p1' />
                            <div className="pd-20">

                                <p>
                                    It is the tight knit, talented team behind Tiles on Bradman Drive that drives our business forward. The Tiles on Bradman Drive team combines a wealth of interior design expertise to deliver the ultimate in customer service.
                                </p>
                                <p>
                                    Whether you are searching for a particular item to complement an existing aesthetic, or are starting from scratch and are in need of some professional guidance and inspiration, we are always ready to assist. Our interior design unit offers free consultations for clients in-store. Bookings are desirable.
                                </p>
                                <p>
                                    There is nobody better equipped to help you discover your design vision than our experienced and highly skilled consultants. We recommend bringing building plans, samples, or colour schemes to get the most out of your meeting.
                                </p>
                            </div>

                        </div>
                    </div>

                    <div>
                        <h3>Large Range That Meets Everyone’s Budget</h3>

                        <div className="res  pb-40">
                            <img className="picture3" src={picture3} alt='p1' />
                            <div className="pd-20">
                                <p>
                                    At Tiles on Bradman Drive, we are proud of our exclusive tiles range.
                                    Customers choose us because we are renowned for offering stunning products sourced from around the world. We demonstrate a sophisticated and refined approach to styling, stocking some exclusive pieces to take interior and exterior spaces to the next level.
                                    Many tiles are imported directly by us, and we are proud of our exclusive collections.
                                    We boast one of the largest ranges in South Australia, encompassing everything from stone to timber-look, and beyond. Our Italian ceramic and porcelain tiles are immensely popular for customers looking to inject instant style into their bathroom, kitchen, or living areas.
                                </p>

                            </div>

                        </div>
                    </div>

                    <div>
                        <h3>Large Range That Meets Everyone’s Budget</h3>

                        <div className="res  pb-40  ">
                            <img className="picture3 " src={picture4} alt='p1' />
                            <div className="pr-30 pt-30 ">
                                <p>
                                    We have the largest range of swimming pool tiles in South Australia.
                                    Choose from ceramic, glass, or stone tiles.
                                </p>

                            </div>

                        </div>
                    </div>




                </Row>
            </Container>
        </div>
    );
};

export default AboutMe;