import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from 'react-select';
import React, { Component, useEffect, useState } from "react";
import Compressor from 'compressorjs';
import backImage from '../../../assets/images/hero/homepage1.jpg'
import { ModalTitle } from "react-bootstrap";
import { DeleteGoods } from "../../../data/api";
import { EditG } from "../../../data/api";
import { ImageDelete } from "../../../data/api";
import "./../style.scss"
const EditGoods = ({ temp, reload, tempName, goodsList, showEG, handleCloseEG }) => {
    const user = JSON.parse(localStorage.getItem("user"));

    const [pName, setPName] = useState(goodsList.tittle);
    const [size, setSize] = useState(goodsList.size);
    const [thickness, setThickness] = useState(goodsList.thickness);
    const [material, setMaterial] = useState(goodsList.material);
    const [goodsImage, setGoodsImage] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [pic, setPic] = useState(temp[0])
    const [pic1, setPic1] = useState(temp[1])
    const [pic2, setPic2] = useState(temp[2])
    const [pic3, setPic3] = useState(temp[3])
    const [pic4, setPic4] = useState(temp[4])
    const [pic5, setPic5] = useState(temp[5])
    const [pic6, setPic6] = useState(temp[6])
    const [pic7, setPic7] = useState(temp[7])
    const [pic8, setPic8] = useState(temp[8])
    const [pic9, setPic9] = useState(temp[9])
    const [Impic, setImPic] = useState(temp[0])
    const [Impic1, setImPic1] = useState(temp[1])
    const [Impic2, setImPic2] = useState(temp[2])
    const [Impic3, setImPic3] = useState(temp[3])
    const [Impic4, setImPic4] = useState(temp[4])
    const [Impic5, setImPic5] = useState(temp[5])
    const [Impic6, setImPic6] = useState(temp[6])
    const [Impic7, setImPic7] = useState(temp[7])
    const [Impic8, setImPic8] = useState(temp[8])
    const [Impic9, setImPic9] = useState(temp[9])
    const [name, setName] = useState(tempName[0])
    const [name1, setName1] = useState(tempName[1])
    const [name2, setName2] = useState(tempName[2])
    const [name3, setName3] = useState(tempName[3])
    const [name4, setName4] = useState(tempName[4])
    const [name5, setName5] = useState(tempName[5])
    const [name6, setName6] = useState(tempName[6])
    const [name7, setName7] = useState(tempName[7])
    const [name8, setName8] = useState(tempName[8])
    const [name9, setName9] = useState(tempName[9])




    const handleChange = async () => {



        const submit = await EditG(
            user[2],
            goodsList.id,
            goodsList.classid,
            pName,
            size,
            thickness,
            material,
            pic,
            pic1,
            pic2,
            pic3,
            pic4,
            pic5,
            pic6,
            pic7,
            pic8,
            pic9,
            name,
            name1,
            name2,
            name3,
            name4,
            name5,
            name6,
            name7,
            name8,
            name9,

        );
        handleCloseEG();
        reload(goodsList.classid);
    };

    //delete goods
    const handleDelete = async () => {

        const dCom = await DeleteGoods(goodsList.classid, goodsList.id);

        if (dCom.ret == 200) {

            handleCloseEG();
            reload(goodsList.classid);
        }

    };

    const deleteImage = async (index) => {
        const deleteI = await ImageDelete(user[2],
            goodsList.id, index);
        if (index == 1) {
            setPic(" ")
            setImPic(" ")
        }
        if (index == 2) {
            setPic1(" ")
            setImPic1(" ")
        }
        if (index == 3) {
            setPic2(" ")
            setImPic2(" ")
        }
        if (index == 4) {
            setPic3(" ")
            setImPic3(" ")
        }
        if (index == 5) {
            setPic4(" ")
            setImPic4(" ")
        }
        if (index == 6) {
            setPic5(" ")
            setImPic5(" ")
        }
        if (index == 7) {
            setPic6(" ")
            setImPic6(" ")
        }
        if (index == 8) {
            setPic7(" ")
            setImPic7(" ")
        }
        if (index == 9) {
            setPic8(" ")
            setImPic8(" ")
        } if (index == 10) {
            setPic9(" ")
            setImPic9(" ")
        }

    };
    const handleImage = (e, index) => {


        const url = URL.createObjectURL(e.target.files[0]);
        if (index == 1) {
            setPic(e.target.files[0])
            setImPic(url)
        }
        if (index == 2) {
            setPic1(e.target.files[0])
            setImPic1(url)
        }
        if (index == 3) {
            setPic2(e.target.files[0])
            setImPic2(url)
        }
        if (index == 4) {
            setPic3(e.target.files[0])
            setImPic3(url)
        }
        if (index == 5) {
            setPic4(e.target.files[0])
            setImPic4(url)
        }
        if (index == 6) {
            setPic5(e.target.files[0])
            setImPic5(url)
        }
        if (index == 7) {
            setPic6(e.target.files[0])
            setImPic6(url)
        }
        if (index == 8) {
            setPic7(e.target.files[0])
            setImPic7(url)
        }
        if (index == 9) {
            setPic8(e.target.files[0])
            setImPic8(url)
        } if (index == 10) {
            setPic9(e.target.files[0])
            setImPic9(url)
        }

    }


    return (
        <Modal show={showEG} onHide={handleCloseEG} size="lg"   >

            <Modal.Header closeButton>

                <ModalTitle>Add {/* {tableType} */} Product</ModalTitle>

            </Modal.Header>
            <Modal.Body className=' d-flex col justify-content-center' style={{ backgroundImage: `url(${backImage})`, objectFit: "cover", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className='contact-form-wrap row  pr-20  pb-30 pt-30 w-100' style={{ width: '90%', backgroundColor: 'white' }}>
                    <div className=' d-flex flex-wrap justify-content-between'>
                        {/*  name */}
                        <div className='col-lg-3 col-6 rld-single-input'>
                            <label htmlFor='title'>Name: </label>
                            <input
                                className='w-100'
                                name='title'
                                type='text'
                                value={pName}

                                onChange={(e) =>
                                    setPName(e.target.value)
                                }
                                required
                            />
                        </div>
                        {/* size */}
                        <div className='col-lg-3 col-6 rld-single-input'>
                            <label htmlFor='title'>Size: </label>
                            <input
                                className='w-100'
                                name='title'
                                type='text'
                                value={size}

                                onChange={(e) =>
                                    setSize(e.target.value)
                                }
                                required
                            />
                        </div>
                        {/* thickness */}
                        <div className=' col-lg-3 col-6 rld-single-input'>
                            <label htmlFor='title'>Thickness: </label>
                            <input
                                className='w-100'
                                name='title'
                                type='text'
                                value={thickness}

                                onChange={(e) =>
                                    setThickness(e.target.value)
                                }
                                required
                            />
                        </div>
                        {/* material */}
                        <div className='col-lg-3 col-6 rld-single-input'>
                            <label htmlFor='title'>Material: </label>
                            <input
                                className='w-100'
                                name='title'
                                type='text'
                                value={material}
                                onChange={(e) =>
                                    setMaterial(e.target.value)
                                }
                                required
                            />
                        </div>
                    </div>

                    {/* pic */}
                    <div className={`image ${(pic != " ") ? 'show' : ''} col-12 d-flex flex-wrap rld-single-input`} >

                        <div className="col-lg-7 col-6 pl-0">
                            <label
                                htmlFor='pic1'
                                className='text-black font-w600'
                                id='pic1'
                            >
                                {/* Upload Picture */}
                                Image1:
                            </label>

                            <div className='custom-file'>
                                <input
                                    type='file'
                                    data-show-preview='false'
                                    className='custom-file-input'
                                    id='upimage'
                                    onChange={(e) => handleImage(e, 1)
                                    }
                                // aria-describedby='customFile'

                                />
                                <label
                                    className='custom-file-label  wordsize'
                                    htmlFor='customFile'
                                >
                                    Choose file
                                </label>

                                <div
                                    className='d-flex flex-wrap justify-content-center'
                                >

                                    {pic != " " ? <div style={{ position: "relative" }}>
                                        <img

                                            style={{
                                                maxHeight: "70px",
                                                maxWidth: "70px",
                                            }}
                                            src={Impic}
                                        />

                                        <button
                                            type="button"

                                            style={{
                                                position: "absolute",
                                                top: "2px",
                                                right: "1px",
                                                border: "none",
                                                borderRadius: "50%",
                                                background: "white",
                                                fontSize: "10px",
                                                color: "black	",
                                                fontWeight: "normal",


                                            }}

                                            onClick={(e) => deleteImage(1)}
                                        >
                                            <a>x</a>
                                        </button>

                                    </div> : null}




                                </div>

                            </div>
                        </div>
                        <div className='col-lg-5 col-6 pr-0'>
                            <label htmlFor='title' className='text-black font-w600'>Color 1: </label>
                            <input
                                className='w-100'
                                name='title'
                                type='text'
                                style={{ height: " 38px" }}
                                value={name}
                                onChange={(e) =>
                                    setName(e.target.value)
                                }
                            />
                        </div>
                    </div>

                    {/* pic1 */}
                    {pic != " " || pic1 != " " ? <div className={`image ${(pic1 != " ") ? 'show' : ''} col-12 d-flex flex-wrap rld-single-input`} >

                        <div className="col-lg-7 col-6 pl-0">
                            <label
                                htmlFor='pic1'
                                className='text-black font-w600'
                                id='pic1'
                            >
                                {/* Upload Picture */}
                                Image2:
                            </label>

                            <div className='custom-file'>
                                <input
                                    type='file'
                                    data-show-preview='false'
                                    className='custom-file-input'
                                    id='upimage'
                                    onChange={(e) => handleImage(e, 2)
                                    }
                                // aria-describedby='customFile'

                                />
                                <label
                                    className='custom-file-label wordsize'
                                    htmlFor='customFile'
                                >
                                    Choose file
                                </label>

                                <div
                                    className='d-flex flex-wrap justify-content-center'
                                >

                                    {pic1 != " " ? <div style={{ position: "relative" }}>
                                        <img

                                            style={{
                                                maxHeight: "70px",
                                                maxWidth: "70px",
                                            }}
                                            src={Impic1}
                                        />

                                        <button
                                            type="button"

                                            style={{
                                                position: "absolute",
                                                top: "2px",
                                                right: "1px",
                                                border: "none",
                                                borderRadius: "50%",
                                                background: "white",
                                                fontSize: "10px",
                                                color: "black	",
                                                fontWeight: "normal",


                                            }}

                                            onClick={(e) => deleteImage(2)}
                                        >
                                            <a>x</a>
                                        </button>

                                    </div> : null}




                                </div>

                            </div>
                        </div>
                        <div className='col-lg-5 col-6 pr-0'>
                            <label htmlFor='title' className='text-black font-w600'>Color 2: </label>
                            <input
                                className='w-100'
                                name='title'
                                type='text'
                                style={{ height: " 38px" }}
                                value={name1}
                                onChange={(e) =>
                                    setName1(e.target.value)
                                }
                            />
                        </div>
                    </div> : null}

                    {/* pic2 */}
                    {pic1 != " " || pic2 != " " ?
                        <div className={`image ${(pic2 != " ") ? 'show' : ''} col-12 d-flex flex-wrap rld-single-input`} >

                            <div className="col-lg-7 col-6 pl-0">
                                <label
                                    htmlFor='pic2'
                                    className='text-black font-w600'
                                    id='pic2'
                                >
                                    {/* Upload Picture */}
                                    Image3:
                                </label>

                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        data-show-preview='false'
                                        className='custom-file-input'
                                        id='upimage'
                                        onChange={(e) => handleImage(e, 3)
                                        }

                                    />
                                    <label
                                        className='custom-file-label wordsize'
                                        htmlFor='customFile'
                                    >
                                        Choose file
                                    </label>

                                    <div
                                        className='d-flex flex-wrap justify-content-center'
                                    >

                                        {pic2 != " " ? <div style={{ position: "relative" }}>
                                            <img

                                                style={{
                                                    maxHeight: "70px",
                                                    maxWidth: "70px",
                                                }}
                                                src={Impic2}
                                            />

                                            <button
                                                type="button"

                                                style={{
                                                    position: "absolute",
                                                    top: "2px",
                                                    right: "1px",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    background: "white",
                                                    fontSize: "10px",
                                                    color: "black	",
                                                    fontWeight: "normal",


                                                }}

                                                onClick={(e) => deleteImage(3)}
                                            >
                                                <a>x</a>
                                            </button>

                                        </div> : null}




                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-5 col-6 pr-0'>
                                <label htmlFor='title' className='text-black font-w600'>Color 3: </label>
                                <input
                                    className='w-100'
                                    name='title'
                                    type='text'
                                    style={{ height: " 38px" }}
                                    value={name2}
                                    onChange={(e) =>
                                        setName2(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        : null}
                    {/* pic3 */}
                    {pic2 != " " || pic3 != " " ?
                        <div className={`image ${(pic3 != " ") ? 'show' : ''} col-12 d-flex flex-wrap rld-single-input`} >

                            <div className="col-lg-7 col-6 pl-0">
                                <label
                                    htmlFor='pic3'
                                    className='text-black font-w600'
                                    id='pic3'
                                >
                                    {/* Upload Picture */}
                                    Image4:
                                </label>

                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        data-show-preview='false'
                                        className='custom-file-input'
                                        id='upimage'
                                        onChange={(e) => handleImage(e, 4)
                                        }


                                    />
                                    <label
                                        className='custom-file-label wordsize'
                                        htmlFor='customFile'
                                    >
                                        Choose file
                                    </label>

                                    <div
                                        className='d-flex flex-wrap justify-content-center'
                                    >

                                        {pic3 != " " ? <div style={{ position: "relative" }}>
                                            <img

                                                style={{
                                                    maxHeight: "70px",
                                                    maxWidth: "70px",
                                                }}
                                                src={Impic3}
                                            />

                                            <button
                                                type="button"

                                                style={{
                                                    position: "absolute",
                                                    top: "2px",
                                                    right: "1px",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    background: "white",
                                                    fontSize: "10px",
                                                    color: "black	",
                                                    fontWeight: "normal",


                                                }}

                                                onClick={(e) => deleteImage(4)}
                                            >
                                                <a>x</a>
                                            </button>

                                        </div> : null}

                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-5 col-6 pr-0'>
                                <label htmlFor='title' className='text-black font-w600'>Color 4: </label>
                                <input
                                    className='w-100'
                                    name='title'
                                    type='text'
                                    style={{ height: " 38px" }}
                                    value={name3}
                                    onChange={(e) =>
                                        setName3(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        : null}
                    {/* pic4 */}
                    {pic3 != " " || pic4 != " " ?
                        <div className={`image ${(pic4 != " ") ? 'show' : ''} col-12 d-flex flex-wrap rld-single-input`} >

                            <div className="col-lg-7 col-6 pl-0">
                                <label
                                    htmlFor='pic1'
                                    className='text-black font-w600'
                                    id='pic1'
                                >
                                    {/* Upload Picture */}
                                    Image5:
                                </label>

                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        data-show-preview='false'
                                        className='custom-file-input'
                                        id='upimage'
                                        onChange={(e) => handleImage(e, 5)
                                        }

                                    />
                                    <label
                                        className='custom-file-label wordsize'
                                        htmlFor='customFile'
                                    >
                                        Choose file
                                    </label>

                                    <div
                                        className='d-flex flex-wrap justify-content-center'
                                    >

                                        {pic4 != " " ? <div style={{ position: "relative" }}>
                                            <img

                                                style={{
                                                    maxHeight: "70px",
                                                    maxWidth: "70px",
                                                }}
                                                src={Impic4}
                                            />

                                            <button
                                                type="button"

                                                style={{
                                                    position: "absolute",
                                                    top: "2px",
                                                    right: "1px",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    background: "white",
                                                    fontSize: "10px",
                                                    color: "black	",
                                                    fontWeight: "normal",


                                                }}

                                                onClick={(e) => deleteImage(5)}
                                            >
                                                <a>x</a>
                                            </button>

                                        </div> : null}

                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-5 col-6 pr-0'>
                                <label htmlFor='title' className='text-black font-w600'>Color 5: </label>
                                <input
                                    className='w-100'
                                    name='title'
                                    type='text'
                                    style={{ height: " 38px" }}
                                    value={name4}
                                    onChange={(e) =>
                                        setName4(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        : null}
                    {/* pic5 */}
                    {pic4 != " " || pic5 != " " ?
                        <div className={`image ${(pic5 != " ") ? 'show' : ''} col-12 d-flex flex-wrap rld-single-input`} >

                            <div className="col-lg-7 col-6 pl-0">
                                <label
                                    htmlFor='pic1'
                                    className='text-black font-w600'
                                    id='pic1'
                                >
                                    {/* Upload Picture */}
                                    Image6:
                                </label>

                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        data-show-preview='false'
                                        className='custom-file-input'
                                        id='upimage'
                                        onChange={(e) => handleImage(e, 6)
                                        }

                                    />
                                    <label
                                        className='custom-file-label wordsize'
                                        htmlFor='customFile'
                                    >
                                        Choose file
                                    </label>

                                    <div
                                        className='d-flex flex-wrap justify-content-center'
                                    >

                                        {pic5 != " " ? <div style={{ position: "relative" }}>
                                            <img

                                                style={{
                                                    maxHeight: "70px",
                                                    maxWidth: "70px",
                                                }}
                                                src={Impic5}
                                            />

                                            <button
                                                type="button"

                                                style={{
                                                    position: "absolute",
                                                    top: "2px",
                                                    right: "1px",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    background: "white",
                                                    fontSize: "10px",
                                                    color: "black	",
                                                    fontWeight: "normal",


                                                }}

                                                onClick={(e) => deleteImage(6)}
                                            >
                                                <a>x</a>
                                            </button>

                                        </div> : null}




                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-5 col-6 pr-0'>
                                <label htmlFor='title' className='text-black font-w600'>Color 6: </label>
                                <input
                                    className='w-100'
                                    name='title'
                                    type='text'
                                    style={{ height: " 38px" }}
                                    value={name5}
                                    onChange={(e) =>
                                        setName5(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        : null}
                    {/* pic6 */}
                    {pic5 != " " || pic6 != " " ?
                        <div className={`image ${(pic6 != " ") ? 'show' : ''} col-12 d-flex flex-wrap rld-single-input`} >

                            <div className="col-lg-7 col-6 pl-0">
                                <label
                                    htmlFor='pic1'
                                    className='text-black font-w600'
                                    id='pic1'
                                >
                                    {/* Upload Picture */}
                                    Image7:
                                </label>

                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        data-show-preview='false'
                                        className='custom-file-input'
                                        id='upimage'
                                        onChange={(e) => handleImage(e, 7)
                                        }
                                    // aria-describedby='customFile'

                                    />
                                    <label
                                        className='custom-file-label wordsize'
                                        htmlFor='customFile'
                                    >
                                        Choose file
                                    </label>

                                    <div
                                        className='d-flex flex-wrap justify-content-center'
                                    >

                                        {pic6 != " " ? <div style={{ position: "relative" }}>
                                            <img

                                                style={{
                                                    maxHeight: "70px",
                                                    maxWidth: "70px",
                                                }}
                                                src={Impic6}
                                            />

                                            <button
                                                type="button"

                                                style={{
                                                    position: "absolute",
                                                    top: "2px",
                                                    right: "1px",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    background: "white",
                                                    fontSize: "10px",
                                                    color: "black	",
                                                    fontWeight: "normal",


                                                }}

                                                onClick={(e) => deleteImage(7)}
                                            >
                                                <a>x</a>
                                            </button>

                                        </div> : null}

                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-5 col-6 pr-0'>
                                <label htmlFor='title' className='text-black font-w600'>Color 7: </label>
                                <input
                                    className='w-100'
                                    name='title'
                                    type='text'
                                    style={{ height: " 38px" }}
                                    value={name6}
                                    onChange={(e) =>
                                        setName6(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        : null}
                    {/* pic7 */}
                    {pic6 != " " || pic7 != " " ?
                        <div className={`image ${(pic7 != " ") ? 'show' : ''} col-12 d-flex flex-wrap rld-single-input`} >

                            <div className="col-lg-7 col-6 pl-0">
                                <label
                                    htmlFor='pic1'
                                    className='text-black font-w600'
                                    id='pic1'
                                >
                                    {/* Upload Picture */}
                                    Image8:
                                </label>

                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        data-show-preview='false'
                                        className='custom-file-input'
                                        id='upimage'
                                        onChange={(e) => handleImage(e, 8)
                                        }
                                    // aria-describedby='customFile'

                                    />
                                    <label
                                        className='custom-file-label wordsize'
                                        htmlFor='customFile'
                                    >
                                        Choose file
                                    </label>

                                    <div
                                        className='d-flex flex-wrap justify-content-center'
                                    >

                                        {pic7 != " " ? <div style={{ position: "relative" }}>
                                            <img

                                                style={{
                                                    maxHeight: "70px",
                                                    maxWidth: "70px",
                                                }}
                                                src={Impic7}
                                            />

                                            <button
                                                type="button"

                                                style={{
                                                    position: "absolute",
                                                    top: "2px",
                                                    right: "1px",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    background: "white",
                                                    fontSize: "10px",
                                                    color: "black	",
                                                    fontWeight: "normal",


                                                }}

                                                onClick={(e) => deleteImage(8)}
                                            >
                                                <a>x</a>
                                            </button>

                                        </div> : null}




                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-5 col-6 pr-0'>
                                <label htmlFor='title' className='text-black font-w600'>Color 8: </label>
                                <input
                                    className='w-100'
                                    name='title'
                                    type='text'
                                    style={{ height: " 38px" }}
                                    value={name7}
                                    onChange={(e) =>
                                        setName7(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        : null}
                    {/* pic8 */}
                    {pic7 != " " || pic8 != " " ?
                        <div className={`image ${(pic8 != " ") ? 'show' : ''} col-12 d-flex flex-wrap rld-single-input`} >

                            <div className="col-lg-7 col-6 pl-0">
                                <label
                                    htmlFor='pic1'
                                    className='text-black font-w600'
                                    id='pic1'
                                >
                                    {/* Upload Picture */}
                                    Image9:
                                </label>

                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        data-show-preview='false'
                                        className='custom-file-input'
                                        id='upimage'
                                        onChange={(e) => handleImage(e, 9)
                                        }

                                    />
                                    <label
                                        className='custom-file-label wordsize'
                                        htmlFor='customFile'
                                    >
                                        Choose file
                                    </label>

                                    <div
                                        className='d-flex flex-wrap justify-content-center'
                                    >

                                        {pic8 != " " ? <div style={{ position: "relative" }}>
                                            <img

                                                style={{
                                                    maxHeight: "70px",
                                                    maxWidth: "70px",
                                                }}
                                                src={Impic8}
                                            />

                                            <button
                                                type="button"

                                                style={{
                                                    position: "absolute",
                                                    top: "2px",
                                                    right: "1px",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    background: "white",
                                                    fontSize: "10px",
                                                    color: "black	",
                                                    fontWeight: "normal",


                                                }}

                                                onClick={(e) => deleteImage(9)}
                                            >
                                                <a>x</a>
                                            </button>

                                        </div> : null}




                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-5 col-6 pr-0'>
                                <label htmlFor='title' className='text-black font-w600'>Color 9: </label>
                                <input
                                    className='w-100'
                                    name='title'
                                    type='text'
                                    style={{ height: " 38px" }}
                                    value={name8}
                                    onChange={(e) =>
                                        setName8(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        : null}
                    {/* pic9 */}
                    {pic8 != " " || pic9 != " " ?
                        <div className={`image ${(pic9 != " ") ? 'show' : ''} col-12 d-flex flex-wrap rld-single-input`} >

                            <div className="col-lg-7 col-6 pl-0">
                                <label
                                    htmlFor='pic1'
                                    className='text-black font-w600'
                                    id='pic1'
                                >
                                    {/* Upload Picture */}
                                    Image10:
                                </label>

                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        data-show-preview='false'
                                        className='custom-file-input'
                                        id='upimage'
                                        onChange={(e) => handleImage(e, 10)
                                        }

                                    />
                                    <label
                                        className='custom-file-label wordsize'
                                        htmlFor='customFile'
                                    >
                                        Choose file
                                    </label>

                                    <div
                                        className='d-flex flex-wrap justify-content-center'
                                    >

                                        {pic9 != " " ? <div style={{ position: "relative" }}>
                                            <img

                                                style={{
                                                    maxHeight: "70px",
                                                    maxWidth: "70px",
                                                }}
                                                src={Impic9}
                                            />

                                            <button
                                                type="button"

                                                style={{
                                                    position: "absolute",
                                                    top: "2px",
                                                    right: "1px",
                                                    border: "none",
                                                    borderRadius: "50%",
                                                    background: "white",
                                                    fontSize: "10px",
                                                    color: "black	",
                                                    fontWeight: "normal",


                                                }}

                                                onClick={(e) => deleteImage(10)}
                                            >
                                                <a>x</a>
                                            </button>

                                        </div> : null}




                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-5 col-6 pr-0'>
                                <label htmlFor='title' className='text-black font-w600'>Color 10: </label>
                                <input
                                    className='w-100'
                                    name='title'
                                    type='text'
                                    style={{ height: " 38px" }}
                                    value={name9}
                                    onChange={(e) =>
                                        setName9(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        : null}

                </div>


            </Modal.Body>
            <Modal.Footer className="justify-content-center">

                <button className="button" onClick={() => handleChange()}>Save</button>
                <button className="button" onClick={() => handleDelete()}>Delete</button>

            </Modal.Footer>
        </Modal >
    );
};
export default EditGoods;