import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useEffect, useState } from "react";
/* import './style.scss'; */
import backImage from '../../assets/images/hero/homepage1.jpg'
import { ModalTitle } from "react-bootstrap";
import { getProduct } from "../../data/api";
import { changeLabel } from "../../data/api";
import { mGetFavorite } from "../../data/api";

const FavoriteDetail = ({ showF, handleCloseF, detail, mGetF, handleDelete }) => {

    const [shopCarList, setShopCarList] = useState([]);
    const [homeShow, setHomeShow] = useState(detail.label);

    //console.log(detail)
    useEffect(() => {

        const getCList = async () => {

            const list = await getProduct(detail.userid, detail.shopcarid);

            // console.log("a", list);
            if (list.ret == "200") {
                setShopCarList(list.data)
            } else {


            }

        }; getCList();

    }, []);

    const handleLabel = async () => {

        const dCom = await changeLabel(detail.id, homeShow);

        if (dCom.ret == 200) {
            mGetF();

            handleCloseF();

        }

    };

    //console.log(shopCarList)
    return (
        <Modal show={showF} onHide={handleCloseF} /* backdrop="static" */ size="lg"   >

            <Modal.Header closeButton>

                <ModalTitle>Favorite Details</ModalTitle>

            </Modal.Header>
            <Modal.Body className=' d-flex col justify-content-center' style={{ backgroundImage: `url(${backImage})`, objectFit: "cover", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className='comDetail row  pr-20  pb-30 pt-30 w-100' style={{ width: '90%', backgroundColor: 'white' }}>

                    {detail.name && (

                        <div className='  w-75'>
                            <h6> Name:  </h6>
                            <p>{detail.name}</p>
                        </div>

                    )}

                    {detail.label && (

                        <div className='  w-75 pt-10'>
                            <h6>Label:  </h6>
                            <div>
                                <input type="radio" value="New" onChange={(e) => setHomeShow(e.target.value)} checked={homeShow == "New"} />
                                <span>New</span>
                            </div>
                            <div>
                                <input type="radio" value="Viewed" onChange={(e) => setHomeShow(e.target.value)} checked={homeShow == "Viewed"} />
                                <span>Viewed</span>
                            </div>
                            <div>
                                <input type="radio" value="Replied" onChange={(e) => setHomeShow(e.target.value)} checked={homeShow == "Replied"} />
                                <span>Replied</span>
                            </div>
                            <div>
                                <input type="radio" value="Shop" onChange={(e) => setHomeShow(e.target.value)} checked={homeShow == "Shop"} />
                                <span>At Shop</span>
                            </div>

                        </div>

                    )}
                    {detail.email && (

                        <div className='  w-75 pt-10'>
                            <h6>Email:  </h6>
                            <p>{detail.email}</p>
                        </div>

                    )}
                    {detail.phone != null && (

                        <div className='  w-75 pt-10'>
                            <h6>Phone number:  </h6>
                            <p>{detail.phone}</p>
                        </div>

                    )}
                    {detail.timestamp && (

                        <div className='  w-75 pt-10'>
                            <h6>Date:  </h6>
                            <p>{detail.timestamp}</p>
                        </div>

                    )}
                    {shopCarList.length > 0 ?
                        <div className='w-100  pt-10 mb-10 '>
                            <h6>Shop List:  </h6>
                            {shopCarList.map((item, index) => (
                                <div className='card contact-form-wrap justify-content-between' style={{ padding: "10px", marginBottom: "10px" }}>

                                    <div key={index} className='row '>

                                        <div className="d-flex col-12">

                                            <div className="col-7">
                                                <h6> Item</h6>
                                                <div className='detailP' >
                                                    <img src={item.picurl} />

                                                </div>
                                            </div>
                                            <div style={{ textAlign: 'left', alignContent: 'center' }}>
                                                <h6 > Details</h6>
                                                <div className="detailC" style={{ color: 'black' }}>

                                                    {item.tittle ? <div> <span>Name: {item.tittle}</span></div> : null}
                                                    {item.quantity != "null" ? <div> <span>Color: {item.quantity}</span></div> : null}
                                                    {item.thickness ? <div><span>Thickness: {item.thickness}</span></div> : null}
                                                    {item.size ? <div> <span>Size: {item.size}</span></div> : null}

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            ))}
                        </div> : null}
                </div>


            </Modal.Body>
            <Modal.Footer className="justify-content-center">

                <button className="button" onClick={() => handleLabel()}>Save</button>
                <button className="button" onClick={() => handleDelete(detail.id)}>Delete</button>

            </Modal.Footer>
        </Modal>
    );
};
export default FavoriteDetail;