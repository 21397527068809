import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import PageContainer from "../../container/CustomPage";
import Blog from "../../components/blog";
import LoadMore from "../../components/loadmore";
import Loading from "../../components/loading";
import blogData from '../../data/blog';
import { productList } from '../../data/api';

const TemplateBlogWithoutSidebar = ({ blogColumnClass }) => {
    const [postsPerPage, setPostsPerPage] = useState(6);
    const [loading, setLoading] = useState(false);
    const posts = blogData.slice(0, postsPerPage);
    const [allData, setAllData] = useState([]);

    const loadMore = () => {
        setLoading(true);
        setTimeout(() => {
            setPostsPerPage(postsPerPage + 3);
            setLoading(false);
        }, 200);
    };

    useEffect(() => {

        const getList = async () => {
            var cpt = []
            const data = await productList();
            //  console.log("list", data.data)

            for (let i = 0; i < data.data.length; i++) {
                if (data.data[i].name == 'CERAMIC POOL TILES') {
                    cpt.push(data.data[i]);
                }
            }

            setAllData(cpt);
        }; getList();

    }, [])
    //  console.log("dataccc", allData)

    const totalPosts = postsPerPage >= allData.length;


    return (
        <PageContainer classes={'bg-grey'}>
            <Container>
                <Row className="blog-content-wrap">
                    {allData.slice(0, postsPerPage).map((item, index) => (
                        <Col xl={4} lg={5} key={index} className={blogColumnClass}>
                            <Blog
                                data={item}
                            /*  thumb={item.thumb}
                             excerpt={item.excerpt}
                             postDate={item.meta.postDate}
                             comments={item.meta.comments}
                             author={item.meta.author} */
                            />
                        </Col>
                    ))}
                </Row>

                <Row>
                    <LoadMore loadBlog={loadMore} totalPosts={totalPosts} />
                </Row>
            </Container>

            {loading ? <Loading /> : null}
        </PageContainer>
    );
};

export default TemplateBlogWithoutSidebar;