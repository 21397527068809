import React, { useState } from 'react';
import NavItem from "./NavItem";
import NavItem2 from "./NavItem2";
import './style.scss';
const NavBar = () => {

    const user = JSON.parse(localStorage.getItem("user" || {}));

    return (
        <div className="header-menu d-lg-flex justify-content-center col-lg-8 d-none">
            <nav className="main-menu">
                <ul>
                    {user ? <NavItem2 /> : <NavItem />}
                </ul>
            </nav>
        </div>
    );
};

export default NavBar;
