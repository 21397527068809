import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import PageContainer from "../../container/CustomPage";
import Blog from "../../components/blog";
import Header from "../../components/header/HeaderOne";
import FooterThree from "../../components/footer/FooterThree";
import SideHeader from "../../components/SideHeader";
import LoadMore from "../../components/loadmore";
import Loading from "../../components/loading";
import SidebarWrap from "../../container/sidebar";
import blogData from '../../data/blog';
/* import { productList } from '../../data/api'; */
import { classGetProduct } from '../../data/api';


const PoolPage = () => {
    const [postsPerPage, setPostsPerPage] = useState(6 || 2);
    const [loading, setLoading] = useState(false);
    const posts = blogData.slice(0, postsPerPage);
    const [allData, setAllData] = useState([]);
    //[id,name]
    //const classInfo = JSON.parse(localStorage.getItem("category"));
    const loadMore = () => {
        setLoading(true);
        setTimeout(() => {
            setPostsPerPage(postsPerPage + 3);
            setLoading(false);
        }, 200);
    };

    useEffect(() => {

        const getList = async () => {
            const data = await classGetProduct(6);
            //console.log("list", data.data)

            if (data.ret == 200) {
                setAllData(data.data);
            }



        }; getList();

    }, [])
    //console.log("dataccc", allData)

    const totalPosts = postsPerPage >= allData.length;


    return (
        <div>
            <Header classes={'position-static'} />
            <SideHeader mobile={true} />

            <PageContainer classes={'bg-grey'}>

                <Container fluid style={{ padding: '50px' }} >
                    <div style={{ marginBottom: '30px' }}> <h1>Pool Tiles</h1> </div>
                    <Row>
                        <Col xl={9} lg={8} className={'mb-50 order-1 order-lg-2'}>
                            <Row className="blog-content-wrap">
                                {allData./* slice(0, postsPerPage). */map((item, index) => (
                                    <Col xl={4} lg={5} key={index} className={'col-12 col-md-6'}>
                                        <Blog
                                            data={item}
                                        /*  thumb={item.thumb}
                                         excerpt={item.excerpt}
                                         postDate={item.meta.postDate}
                                         comments={item.meta.comments}
                                         author={item.meta.author} */
                                        />
                                    </Col>
                                ))}
                            </Row>

                            {/* <Row>
                                <LoadMore loadBlog={loadMore} totalPosts={totalPosts} />
                            </Row> */}
                        </Col>

                        <Col xl={3} lg={4} className={'order-2 order-lg-1'}>
                            <SidebarWrap data={allData} />
                        </Col>
                    </Row>
                </Container>

                {loading ? <Loading /> : null}
            </PageContainer>
            <FooterThree position={'static'} />
        </div>

    );
};

export default PoolPage;