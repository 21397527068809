import React from 'react';
import { Link } from 'react-router-dom'


const Portfolio = ({ thumb, classes, title, Id, variant }) => {

    const handlepage = (item) => {
        localStorage.setItem("category", JSON.stringify([item, title]));
    };
    return (
        <div className={`portfolio-item ${classes ? classes : ' '}`} >
            <Link to={`/product/${Id}`} onClick={() => handlepage(Id)} className={'d-block'}>
                {variant === 'column' ? (
                    <div className="portfolio-image" style={{ backgroundSize: 'cover' }} >
                        <img src={thumb} alt={title} />
                    </div>

                ) : (
                    <div className="portfolio-image"
                        style={{ backgroundImage: `url(${require('../../assets/images/' + thumb)})` }} />
                )}
                <div className="portfolio-content">
                    <h4 className="title">{title}</h4>
                    {/* <div className="category"><span>{category}</span></div> */}
                </div>
            </Link>
        </div>
    );
};

export default Portfolio;
