import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useEffect, useState } from "react";
/* import './style.scss'; */
import backImage from '../../assets/images/hero/homepage1.jpg'
import { ModalTitle } from "react-bootstrap";


const ComDetail = ({ show, handleClose, detail, handleComment }) => {
    //console.log(detail.phone)


    return (
        <Modal show={show} onHide={handleClose} /* backdrop="static" */ size="lg"   >

            <Modal.Header closeButton>

                <ModalTitle>Comments Details</ModalTitle>

            </Modal.Header>
            <Modal.Body className=' d-flex col justify-content-center' style={{ backgroundImage: `url(${backImage})`, objectFit: "cover", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className='comDetail row  pr-20  pb-30 pt-30 w-100' style={{ width: '90%', backgroundColor: 'white' }}>

                    {detail.firstname && detail.lastname && (

                        <div className='  w-75'>
                            <h6> Name:  </h6>
                            <p>{detail.firstname}  {detail.lastname}</p>
                        </div>

                    )}

                    {detail.companyname && (

                        <div className='  w-75 pt-10'>
                            <h6>Company Name:  </h6>
                            <p>{detail.companyname}</p>
                        </div>

                    )}
                    {detail.email && (

                        <div className='  w-75 pt-10'>
                            <h6>Email:  </h6>
                            <p>{detail.email}</p>
                        </div>

                    )}
                    {detail.phone && (

                        <div className='  w-75 pt-10'>
                            <h6>Phone number:  </h6>
                            <p>{detail.phone}</p>
                        </div>

                    )}
                    {detail.timestamp && (

                        <div className='  w-75 pt-10'>
                            <h6>Date and Time:  </h6>
                            <p>{detail.timestamp}</p>
                        </div>

                    )}
                    {detail.content && (

                        <div className=' w-78 pr-40 pt-10'>
                            <h6>Description:  </h6>
                            <p>{detail.content}</p>
                        </div>

                    )}

                </div>


            </Modal.Body>
            <Modal.Footer className="justify-content-center">

                <button onClick={() => handleComment(detail.id)}>Delete</button>


            </Modal.Footer>
        </Modal>
    );
};
export default ComDetail;