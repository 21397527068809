import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import PageContainer from "../../container/CustomPage";
import Service from "../../components/service";
import services from '../../data/service'
import { getComment } from "../../data/api";
const TemplateService = () => {

    const [allCom, setAllCom] = useState([]);

    useEffect(() => {

        const comments = async () => {

            const data = await getComment();
            //console.log("data", data)
            setAllCom(data.data)
        }
        comments();

    }, []);
    // console.log("allCom", allCom)

    return (

        <PageContainer classes={'bg-grey'}>
            <Container >
                <Row className={'service-bg'}>
                    {Array.isArray(allCom) && allCom.map((item, index) => (
                        <Col key={index} md={6} className={'mb-40 mb-xs-30'}>
                            <div style={{ padding: "50px", boxShadow: "0px 2px 12px #7C6F6F" }}>
                                <Service
                                    title={item.companyname}
                                    content={item.content}
                                    item={item}
                                />

                            </div>

                        </Col>
                    ))}
                </Row>
            </Container>
        </PageContainer>
    );
};

export default TemplateService;