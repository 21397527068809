import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import PageContainer from "../../container/CustomPage";
import Blog from "../../components/blog";
import LoadMore from "../../components/loadmore";
import Loading from "../../components/loading";
import SidebarWrap from "../../container/sidebar";
import blogData from '../../data/blog';

const NewGoods = ({ blogColumnClass }) => {

    return (
        <PageContainer classes={'bg-grey'}>
            <Container >
                <div className='register-page-area pd-bottom-100'>

                    <div className='row justify-content-center'>
                        <div className='col-xl-10 col-lg-10 col-md-10 mb-5 mb-md-0'>
                            <div className='card contact-form-wrap contact-form-bg'>
                                {/* <h4>Submit Question</h4> */}

                                <div className='d-flex justify-content-between'>


                                </div>
                                <div className='rld-single-input'>
                                    <label htmlFor='title'>Title:</label>
                                    <input
                                        className='w-100'
                                        name='title'
                                        type='text'
                                        placeholder='Product name'
                                        /*     onChange={(e) =>
                                                setTitle(e.target.value)
                                            } */
                                        required
                                    />
                                </div>


                                <div className="d-flex justify-content-center">

                                    <div className='rld-single-input'>
                                        <label htmlFor='city'>Price:</label>

                                        <input
                                            className='col-sm'
                                            name='city'
                                            type='text'
                                            placeholder='Product price'
                                        /*  onChange={(e) =>
                                             setCity(e.target.value)
                                         } */
                                        />
                                    </div>
                                    <div className='rld-single-input' style={{ paddingLeft: "20px" }}>
                                        <label htmlFor='Postcode'>Product size:</label>
                                        <input
                                            className='col-sm'
                                            name='Postcode'
                                            type='text'
                                            placeholder='-'
                                        /*  onChange={(e) =>
                                             setPostcode(e.target.value)
                                         } */
                                        />
                                    </div>


                                    <div className='rld-single-input' style={{ paddingLeft: "20px" }}>
                                        <label htmlFor='Land-size'>
                                            Color:
                                        </label>
                                        <input
                                            className='col-sm'
                                            name='Land-size'
                                            type='text'
                                            placeholder='color'
                                        /* onChange={(e) =>
                                            setSize(e.target.value)
                                        } */
                                        />
                                    </div>
                                    <div className='rld-single-input' style={{ paddingLeft: "20px" }}>
                                        <label htmlFor='Postcode'>Quantity:</label>
                                        <input
                                            className='col-sm'
                                            name='Postcode'
                                            type='text'
                                            placeholder='-'
                                        /*  onChange={(e) =>
                                             setPostcode(e.target.value)
                                         } */
                                        />
                                    </div>

                                </div>


                                <div className='rld-single-input'>
                                    <label htmlFor='question'>Product Description:</label>
                                    <textarea
                                        name='question'
                                        type='text'
                                        placeholder='Description'

                                        /*  onChange={(e) =>
                                             setQuestion(e.target.value)
                                         } */
                                        required
                                    />
                                </div>

                                <div
                                    className='rld-single-input'

                                >
                                    <label
                                        htmlFor='pic'
                                        className='text-black font-w600'
                                        id='pic'
                                    >
                                        {/* Upload Picture */}
                                        Upload Picture
                                    </label>

                                    <div className='custom-file'>
                                        <input
                                            type='file'
                                            multiple
                                            data-show-preview='false'
                                            className='custom-file-input'
                                            id='upimage'
                                        /*  onChange={(e) => handleImage(e)
                                         } */
                                        // aria-describedby='customFile'

                                        />
                                        <label
                                            className='custom-file-label'
                                            htmlFor='customFile'
                                        >
                                            Choose file
                                        </label>



                                    </div>
                                </div>

                                <div className='btn-wrap d-flex justify-content-center align-items-center'>
                                    <button
                                        className='btn btn-yellow'
                                    //style={{ marginTop: "100px" }}
                                    /*  onClick={handleSubmit} */
                                    >
                                        {/* Submit question */}
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Container>
        </PageContainer>
    );
};

export default NewGoods;