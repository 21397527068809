import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from 'react-select';
import React, { Component, useEffect, useState } from "react";
import './categoryM.scss';
import backImage from '../../../assets/images/hero/homepage1.jpg'
import { ModalTitle } from "react-bootstrap";
import { NewProduct } from "../../../data/api";
import { notification } from "antd";
import { FrownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const AddNewC = ({ show, home, handleHome, handleClose, handleImage, compressedFile }) => {

    const [pName, setPName] = useState("");

    const options = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" }
    ];

    const handleSubmit = async () => {

        if (pName != "" && home != null && compressedFile != null) {
            const submit = await NewProduct(
                pName,
                home,
                compressedFile,
            )
            handleClose();
            window.location.reload()
        } else {
            notification.open({
                message: 'Error',
                description: 'No image or Category Name',
                placement: 'btmRight',
                icon: <ExclamationCircleOutlined style={{ color: "red" }} />

            });
        }

    };

    return (
        <Modal show={show} onHide={handleClose} size="md"   >

            <Modal.Header closeButton>

                <ModalTitle>New Product Category</ModalTitle>

            </Modal.Header>
            <Modal.Body className=' d-flex col justify-content-center' style={{ backgroundImage: `url(${backImage})`, objectFit: "cover", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className='contact-form-wrap row  pr-20  pb-30 pt-30 w-100' style={{ width: '90%', backgroundColor: 'white' }}>

                    <div
                        className='col-12 rld-single-input'

                    >
                        <label
                            htmlFor='pic'
                            className='text-black font-w600'
                            id='pic'
                        >
                            {/* Upload Picture */}
                            Upload Picture
                        </label>

                        <div className='custom-file'>
                            <input
                                type='file'
                                data-show-preview='false'
                                className='custom-file-input'
                                id='upimage'
                                onChange={(e) => handleImage(e)
                                }
                            // aria-describedby='customFile'

                            />
                            <label
                                className='custom-file-label'
                                htmlFor='customFile'
                            >
                                {compressedFile != null ? <span>{compressedFile.name}</span> : <span> Choose file</span>}
                            </label>



                        </div>
                    </div>

                    <div className='col-12 justify-content-between'>
                        <div className='rld-single-input'>
                            <label htmlFor='title'>Name:</label>
                            <input
                                className='w-100'
                                name='title'
                                type='text'
                                placeholder='Product name'
                                onChange={(e) =>
                                    setPName(e.target.value)
                                }
                                required
                            />
                        </div>

                        {/*    <div className='rld-single-input' style={{ paddingLeft: "20px" }}>
                            <label htmlFor='Postcode'>Product size:</label>
                            <input
                                className='col-sm'
                                name='Postcode'
                                type='text'
                                placeholder='-'
                             onChange={(e) =>
                                 setPostcode(e.target.value)
                             }
                            />
                        </div> */}

                        <div className=' rld-single-input'>
                            <label htmlFor='Postcode'>Home Show:</label>
                            <Select onChange={(e) => handleHome(e)

                            } options={options} />
                        </div>

                    </div>



                    {/* <div className='rld-single-input'>
                        <label htmlFor='question'>Product Description:</label>
                        <textarea
                            name='question'
                            type='text'
                            placeholder='Description'

                             onChange={(e) =>
                                 setQuestion(e.target.value)
                             }
                            required
                        />
                    </div> */}



                </div>


            </Modal.Body>
            <Modal.Footer className="justify-content-center">

                <button onClick={() => handleSubmit()}>Submit</button>


            </Modal.Footer>
        </Modal>
    );
};
export default AddNewC;