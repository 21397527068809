import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useEffect, useState } from "react";
import './style.scss';
import { ModalTitle } from "react-bootstrap";
import { Container, Table } from 'react-bootstrap'
import HistoryDetail from "./history";
const ShopHistory = ({ show, handleClose, hList }) => {

    //  console.log("A", hList)
    const [showF, setShowF] = useState(false);//Favorite
    const [favoriteD, setFavoriteD] = useState();

    const handleShowF = (c) => {
        //     console.log("yes")
        setFavoriteD(c)
        setShowF(true);
    };
    const handleCloseF = () => {
        setShowF(false);

    };

    //Favorite
    const renderF = (hList, index) => {
        return (
            <tr key={index}>
                <td >{index + 1}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{hList.timestamp/* .substring(0, 10) */}</td>
                <td><button style={{ padding: '5px', fontSize: "15px" }} className="mt-10 mb-10 editB" onClick={() => handleShowF(hList)}>Details</button></td>

            </tr>
        )
    }
    //  console.log("cl", show)
    return (
        <Modal show={show} onHide={handleClose} size="lg" >

            <Modal.Header closeButton>

                <ModalTitle>ShopCart History</ModalTitle>

            </Modal.Header>
            <Modal.Body>
                <Table className="pt-10 table-bordered table-hover table-dark" responsive="sm" responsive="md" responsive="sm" responsive="xl" size="sm">

                    <thead className="thead-primary" style={{ textAlign: 'center' }}>
                        <tr >

                            <th >No</th>
                            <th>Date</th>
                            <th>_</th>
                        </tr>


                    </thead>

                    {hList.length > 0 ? <tbody className="body" >
                        {hList.map(renderF)}
                        {showF ? <HistoryDetail
                            showF={
                                showF
                            }
                            handleCloseF={
                                handleCloseF
                            }

                            detail={
                                favoriteD
                            }
                        /> : null}
                    </tbody> : null}

                </Table>


            </Modal.Body>
            <Modal.Footer className="justify-content-center">




            </Modal.Footer>
        </Modal>
    );
};
export default ShopHistory;