import React, { useState } from 'react';
import PropTypes from "prop-types";
import './style.scss'
import BigImageS from './showBig';
const PortfolioDetailsThumb = ({ images, details, picColor, handleName, hanldeColor }) => {
    const [show, setShow] = useState(false);
    const [number, setNumber] = useState(picColor)
    const [image, setImage] = useState();
    const [name, setName] = useState("");

    if (number != picColor) {
        setNumber(picColor)
    }

    const handleTap = (index) => {
        setNumber(index);
        hanldeColor(index);
        handleName(index);

    }
    const handleShow = (i) => {
        setShow(true);
        setImage(i)

        if (number == 0) {
            setName(details.picname1)

        }
        if (number == 1) {

            setName(details.picname2)
        }
        if (number == 2) {
            setName(details.picname3)

        }
        if (number == 3) {

            setName(details.picname4)
        }
        if (number == 4) {
            setName(details.picname5)

        }
        if (number == 5) {

            setName(details.picname6)
        }
        if (number == 6) {

            setName(details.picname7)
        }
        if (number == 7) {

            setName(details.picname8)
        }
        if (number == 8) {

            setName(details.picname9)
        } if (number == 9) {

            setName(details.picname10)
        }
    };
    const handleClose = () => {
        setShow(false);

    };



    return (
        <div className="product-details-image mb-sm-50 mb-xs-30">

            <div className="big-img">
                <img style={{ height: "100%", width:"auto", objectFit: "cover" }} type="button" onClick={() => handleShow(images[number])} src={images[number]} alt="product" />

            </div>

            <br />
            <BigImageS
                show={
                    show
                }
                handleClose={
                    handleClose
                }
                image={
                    image
                }
                picname={name}

            />
            <div className="thumb "/*  ${images.length < 7 ? 'less' : ''} */>
                {images.map((image, index) => (
                    <img key={index} src={image} alt="portfolio"
                        onClick={() => handleTap(index)}
                    />
                ))}

            </div>

        </div>
    );
};


export default PortfolioDetailsThumb;