import { ApplyToken, submitForm } from "../../data/api";
import React, { useEffect, useState } from 'react';
import Input from "../input";
import ContactPage from "../../pages/contact";
import Contact from "../../container/contact";
const Form = ({ token }) => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [content, setContent] = useState("");


    const handleSubmit = async () => {


        if (firstName, lastName, email, phone, content != "") {

            const submit = await submitForm(
                // token.access_token,
                firstName,
                lastName,
                email,
                phone,
                companyName,
                content
            );

            if (submit.ret === 200) {

            } else {
                // console.log("aa", submit.ret)
            }
            //  console.log("aa", submit)

        }

        /* if (submit.ret === 200) {


            // history.push("./");
            window.location.reload();

        } */

    };


    //console.log(firstName, lastName)

    return (

        <form  >
            <input type={'text'} id={'FirstN'} name={'FirstName'} placeholder={'First Name *'} required={true} onChange={(e) => setFirstName(e.target.value)} />
            <input type={'text'} id={'LastN'} name={'LastName'} placeholder={'Last Name *'} required={true} onChange={(e) => setLastName(e.target.value)} />
            <input type={'mobile'} id={'phone'} name={'phone'} placeholder={'Phone *'} required={true} onChange={(e) => setPhone(e.target.value)} />
            <input type={'email'} id={'email'} name={'email'} placeholder={'Email *'} required={true} onChange={(e) => setEmail(e.target.value)} />
            <input type={'text'} id={'companyName'} name={'companyName'} placeholder={'Company Name'} required={false} onChange={(e) => setCompanyName(e.target.value)} />
            <textarea id={'message'} name={'message'} placeholder={'Message *'} required={true} onChange={(e) => setContent(e.target.value)} />
            <button onClick={handleSubmit}>Send</button>
        </form >
    );
};

export default Form;