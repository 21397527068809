import { style } from 'dom-helpers';
import React, { useState, useEffect } from 'react';
import { shoppingCart, addProduct } from '../../../../data/api';

import './style.scss'


const PortfolioDetailsContent = ({ data, handleName, picName, handleColor, selectedImg }) => {

    const [allSize, setAllSize] = useState([]);
    const [allThickeness, setAllThickness] = useState([]);
    const [allMaterial, setAllMaterial] = useState([]);
    const [s, setS] = useState("");
    const [t, setT] = useState("");
    const [m, setM] = useState("");
    const [colorN, setColorN] = useState(data.picname1);
    const user = JSON.parse(localStorage.getItem("user"));
    const [pNumber, setPnumber] = useState(0);
    const [title, setTitle] = useState(data.tittle);

    const [cName, setCName] = useState(picName);
    const goodsid = data.id

    var userid;
    let name = [];

    if (cName != picName) {
        setCName(picName)
    }

    if (data.picname1 != "null" && data.picname1 != "") {
        name.push(data.picname1)

    }
    if (data.picname2 != "null" && data.picname2 != "") {
        name.push(data.picname2)
    }
    if (data.picname3 != "null" && data.picname3 != "") {
        name.push(data.picname3)
    }
    if (data.picname4 != "null" && data.picname4 != "") {
        name.push(data.picname4)
    }
    if (data.picname5 != "null" && data.picname5 != "") {
        name.push(data.picname5)
    }
    if (data.picname6 != "null" && data.picname6 != "") {
        name.push(data.picname6)
    }
    if (data.picname7 != "null" && data.picname7 != "") {
        name.push(data.picname7)
    }
    if (data.picname8 != "null" && data.picname8 != "") {
        name.push(data.picname8)
    }
    if (data.picname9 != "null" && data.picname9 != "") {
        name.push(data.picname9)
    }
    if (data.picname10 != "null" && data.picname10 != "") {
        name.push(data.picname10)
    }

    useEffect(() => {

        //size
        const checkSize = () => {
            let stringSize = "";
            let sizeArray = [];
            for (let i = 0; i < data.size.length; i++) {
                // console.log(data.size[0])
                if (data.size[i] != ",") {
                    stringSize = stringSize + data.size[i];
                    if (i == data.size.length - 1) {
                        sizeArray.push(stringSize);
                        stringSize = "";
                    }
                } else if (data.size[i] == ",") {
                    sizeArray.push(stringSize);
                    stringSize = "";
                }
            }
            setAllSize(sizeArray)
            setS(sizeArray[0])
        }; checkSize();

        //thickness
        const checkThickness = () => {
            let stringThickness = "";
            let thicknessArray = [];
            for (let i = 0; i < data.thickness.length; i++) {

                if (data.thickness[i] != ",") {
                    stringThickness = stringThickness + data.thickness[i];
                    if (i == data.thickness.length - 1) {
                        thicknessArray.push(stringThickness);
                        stringThickness = "";
                    }
                } else if (data.thickness[i] == ",") {
                    thicknessArray.push(stringThickness);
                    stringThickness = "";
                }
            }
            setAllThickness(thicknessArray)
            setT(thicknessArray[0])
        }; checkThickness();

        //material
        const checkMaterial = () => {

            let stringMaterial = "";
            let materialArray = [];
            for (let i = 0; i < data.material.length; i++) {
                // console.log(data.material[0])
                if (data.material[i] != ",") {
                    stringMaterial = stringMaterial + data.material[i];
                    if (i == data.material.length - 1) {
                        materialArray.push(stringMaterial);
                        stringMaterial = "";
                    }
                } else if (data.material[i] == ",") {
                    materialArray.push(stringMaterial);
                    stringMaterial = "";
                }
            }
            setAllMaterial(materialArray)
            setM(materialArray[0])
        }; checkMaterial();

    }, []);

    // setPicName(name);

    if (user === null) {

        userid = 1;
    } else {

        userid = user[0]
    }

    //Addd new car
    const handleCart = async () => {

        if (localStorage.getItem("shopId") == null) {
            // console.log("aaau", userid)
            const shopId = await shoppingCart(

                userid
            );

            localStorage.setItem("shopId", JSON.stringify(shopId.data.id));

            const data = await addProduct(
                goodsid,
                userid,
                //size
                s,
                //thickness
                t,
                //material

                //color
                colorN,
                shopId.data.id,
                title,
                selectedImg

            );
            // console.log("shopId", data, goodsid, userid, s, t, quantity, shopId.data.id, title, pic);
            window.location.reload();
        } else {
            const currentId = JSON.parse(localStorage.getItem("shopId"));

            const data = await addProduct(
                goodsid,
                userid,
                //size
                s,
                //thickness
                t,
                //color
                colorN,
                currentId,
                title,
                selectedImg

            );
            // console.log("shopId", data, goodsid, userid, s, t, quantity, currentId, title, pic);
            window.location.reload();
            // console.log("number", data)
        }

        /* setPnumber(number); */

    }

    const handleNindex = (e) => {
        setColorN(e)
        const indexName = name.indexOf(e);
        handleColor(indexName)
        handleName(indexName)
        setCName(e)

    }

    return (


        <div className="portfolio-details-content">

            <h1 className="title">{data.tittle}</h1>

            <div className=" d-flex pb-10">
                {name.length > 0 ? <div className="single-input">
                    <label>Color: </label>
                    <div>
                        <select value={cName} onChange={(e) => { handleNindex(e.target.value) }}>
                            {/*  <option selected>SELECT SIZE:</option> */}
                            {name.map((names, key) => (
                                <option value={names} index={key}>
                                    {names}
                                </option>

                            ))}
                        </select>
                    </div>

                    {/* size */}
                </div> : null}
                {data.size != "" ? <div className="single-input">
                    <label>Size: </label>
                    <div>
                        <select onChange={(e) =>
                            setS(e.target.value)

                        }>
                            {/*  <option selected>SELECT SIZE:</option> */}
                            {allSize.map((sizes) => (
                                <option value={sizes}>
                                    {sizes}
                                </option>

                            ))}
                        </select>
                    </div>


                </div> : null}
                {/* material */}
                {data.material != "" ? <div className="single-input">
                    <label>Material: </label>
                    <div>
                        <select onChange={(e) =>
                            setM(e.target.value)

                        }>
                            {/*  <option selected>SELECT SIZE:</option> */}
                            {allMaterial.map((materials) => (
                                <option value={materials}>
                                    {materials}
                                </option>

                            ))}
                        </select>

                    </div>

                </div> : null}
                {/* thickness */}
                {data.thickness != "" ? <div className="single-input">
                    <label>Thickness: </label>

                    <div>
                        <select onChange={(e) =>
                            setT(e.target.value)

                        }>
                            {/* <option selected>SELECT THICKNESS:</option> */}
                            {allThickeness.map((thickness) => (
                                <option value={thickness}>
                                    {thickness}
                                </option>

                            ))}
                        </select>
                    </div>

                </div> : null}


            </div>

            <button className="cart " onClick={handleCart}>Add to Wishlist</button>

        </div>



    );
};

export default PortfolioDetailsContent;