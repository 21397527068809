import React from 'react';

const Map = ({ text, lat, long, address }) => {

    // console.log(text)
    return (
        <div className="row">
            <div className="col-lg-10 col-12 mb-45">
                <div className="contact-availability">
                    <h3>Working Hours</h3>
                    {text.map((item, Day) => (
                        <p key={Day}>{item.Day}: {item.Time}</p>
                    ))}
                </div>
            </div>
            <div className="col-lg-11 col-12">
                <div id="contact-map">
                    <iframe
                        src={`https://maps.google.com/maps?q=${address}&hl=en&z=12&output=embed`}
                        title={'Contact Map'}
                        style={{ width: '100%', height: '100%', border: 0 }}
                    />

                </div>
            </div>
        </div>
    );
};

export default Map;