import { ApplyToken } from "../../data/api";
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import Form from "../../components/form";
import QuickContact from "../../components/QuickContact";
import Map from "../../components/map";
import Notification from '../../components/notification/notification';
const workingHours = [
    {
        Day: 'Monday',
        Time: '8am - 5pm'
    },
    {
        Day: 'Tuesday',
        Time: '8am - 5pm'
    },
    {
        Day: 'Wednesday',
        Time: '8am - 5pm'
    },
    {
        Day: 'Thursday',
        Time: '8am - 5pm'
    },
    {
        Day: 'Friday',
        Time: '8am - 5pm'
    },
    {
        Day: 'Saturday',
        Time: '8:30am - 4pm'
    },
    {
        Day: 'Sunday',
        Time: 'Closed'
    },
]
const Contact = () => {



    return (

        <div className="bg-grey section ">

            <div
                className="section-wrap section pt-1200 pt-md-0 pt-sm-0 pt-xs-0 pb-120 pb-lg-0 pb-md-0 pb-sm-0 pb-xs-0">
                <Container>
                    <Row>
                        <Col lg={6} className={'mb-sm-45 mb-xs-45'}>
                            <Row>
                                <Col lg={10} className={'mb-45'}>
                                    <div className="contact-form">
                                        <h3>CONTACT FORM</h3>
                                        <Form />
                                    </div>
                                </Col>

                                <Col lg={10}>
                                    <QuickContact
                                        phone={'(08) 8443 9111'}
                                        // fax={'(08) 8443 5448'}
                                        email={'sales@tilesonbradman.com.au'}
                                        location={'119 Sir Donald Bradman Drive, Hilton SA 5033'}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={6}>
                            <Map
                                text={workingHours}
                                lat={-34.9323969}
                                long={138.5638728}
                                address={'119 Sir Donald Bradman Drive,Hilton SA 5033'}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Contact;