import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useEffect, useState } from "react";
import './style.scss';
import { ModalTitle } from "react-bootstrap";
import Quote from "./quote";
const Detail = ({ show, handleClose, handleQ, list }) => {

    //  console.log("cl", show)
    return (
        <Modal show={show} onHide={handleClose} backdrop="static" size="lg" >

            <Modal.Header closeButton>

                <ModalTitle>Item Details</ModalTitle>

            </Modal.Header>
            <Modal.Body>
                <div className='w-100   mb-10 '>
                    {list.map((item, index) => (
                        <div className='card contact-form-wrap justify-content-between' style={{ marginBottom: "10px" }}>

                            <div key={index} className='row '>

                                <div className="d-flex col-12">

                                    <div className="col-7">
                                        <h6> Item</h6>
                                        <div className='detailP' >
                                            <img src={item.picurl} />

                                        </div>
                                    </div>
                                    <div style={{ textAlign: 'left', alignContent: 'center' }}>
                                        <h6 > Details</h6>
                                        <div className="detailC" style={{ color: 'black' }}>

                                            {item.tittle ? <div> <span>Name: {item.tittle}</span></div> : null}
                                            {item.thickness ? <div><span>Thickness: {item.thickness}</span></div> : null}
                                            {item.size ? <div> <span>Size: {item.size}</span></div> : null}

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    ))}
                </div>


            </Modal.Body>
            <Modal.Footer className="justify-content-center">

                <button className="qbutton" onClick={handleQ}>Confirm</button>


            </Modal.Footer>
        </Modal>
    );
};
export default Detail;