import React, { Fragment, useState, useEffect } from 'react';
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import FooterThree from "../../components/footer/FooterThree";
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import Detail from './shopDetail.js/details';
import './cart.scss'
import { getProduct } from '../../data/api';
import Quote from './shopDetail.js/quote';
import { DeleteCarGoods } from '../../data/api';
import { shoppingCart } from '../../data/api';
import ShopHistory from './shopDetail.js/shopHistory';
import { ShopHistoryUser } from '../../data/api';

const Shopping = () => {
    var userid;
    var hList = [];
    //  const [shopcartId, setShopcartId] = useState();
    const [goodList, setGoodList] = useState([]);
    const [shopHList, setShopHList] = useState([]);
    const [noList, setNotList] = useState(true);
    const [show, setShow] = useState(false);
    const [showH, setShowH] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const carId = JSON.parse(localStorage.getItem("shopId"));
    const shopHisty = JSON.parse(localStorage.getItem("shopHistory"));
    const [fot, setFot] = useState("fixed");
    const [q, setQ] = useState(false)
    if (user === null) {

        userid = 1;
    } else {

        userid = user[0]
    }


    //new Shop car
    const handleCart = async () => {

        const history = JSON.parse(localStorage.getItem("shopHistory"));
        if (history != null) {
            var carH = history
            carH.push(carId)
        } else {
            var carH = []
            carH.push(carId)
        }


        localStorage.setItem("shopHistory", JSON.stringify(carH));

        const shopId = await shoppingCart(

            userid
        );
        setFot("Fixed")
        setNotList(true);
        setGoodList([])
        localStorage.setItem("shopId", JSON.stringify(shopId.data.id));

    }
    //quote
    const handleQ = async () => {

        handleClose();
        setQ(true);

    }
    const hClose = () => {

        setQ(false);
    }
    const getHList = async (item) => {
        // console.log(item)
        const list = await getProduct(userid, item);
        //  console.log(list)
        hList.push(list.data[0])
        //  console.log(hList)
        setShopHList([...hList])
    };

    const handleShowH = () => {

        if (userid != 1) {
            const history = JSON.parse(localStorage.getItem("shopHistory"));
            for (let i = 0; i < history.length; i++) {

                getHList(history[i]);
            }
        }

        setShowH(true);
    };
    const handleCloseH = () => {
        setShowH(false);

    };
    //check out
    const handleShow = () => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);

    };

    const handleDelete = async (item) => {

        const dCom = await DeleteCarGoods(item.goodsid, carId);

        if (dCom.ret == 200) {


            window.location.reload();
        }

    };
    // setGoodList(ta);
    // console.log("good", goodList);

    useEffect(() => {

        if (carId == null || carId == "0") {

        } else {
            const getCList = async () => {

                const list = await getProduct(userid, carId);

                //  console.log("a", list);
                if (list.ret == "400") {
                    setFot("Fixed")
                } else {
                    //console.log("error:")
                    setNotList(false);
                    setGoodList(list.data);
                    setFot("static");

                }

            }; getCList();
        }


        const getUHList = async () => {

            const UHlist = await ShopHistoryUser(userid);


            if (UHlist.ret == "200") {
                setShopHList(UHlist)
            }

        }; getUHList();


    }, []);


    const handlePage = (item) => {
        localStorage.setItem("category", JSON.stringify(item));
    };
    return (
        <Fragment>
            <Header classes={'position-static'} />
            <SideHeader mobile={true} />
            <div className="bg-grey section" >

                <div className='container'>
                    <h1>Wish List</h1>
                    {shopHisty != null ? <button className="checkout d-flex justify-content-center" style={{}} onClick={handleShowH}>History</button> : null}
                    <ShopHistory
                        show={
                            showH
                        }
                        handleClose={
                            handleCloseH
                        }
                        hList={
                            shopHList
                        }
                    />
                    <div className='row justify-content-center'>

                        {noList ?
                            <span className='pt-10 pb-10 pl-20 pr-20'>
                                You have nothing in your Wishlist
                                <a href='/poolTiles' onClick={() => handlePage("Pool Tiles")} >. Continue to add</a>
                            </span> :


                            <div className='w-100  mb-10 '>

                                {goodList.map((item, index) => (
                                    <div className='card contact-form-wrap justify-content-between' style={{ marginBottom: "10px" }}>

                                        <div key={index} className='row shop '>

                                            <div className="d-flex col-12">

                                                <div className="col-lg-7 col-9">
                                                    <h5> Item</h5>
                                                    <div className='goods-image  col-12  ' >
                                                        <img src={item.picurl} />

                                                    </div>
                                                </div>
                                                <div className="col-4 " style={{ textAlign: 'left', alignContent: 'center' }}>
                                                    <div style={{ textAlign: 'left', alignContent: 'center' }}>
                                                        <h6 > Details</h6>
                                                        <div className="detailC" style={{ color: 'gray' }}>

                                                            {item.tittle ? <div> <span>Name: {item.tittle}</span></div> : null}
                                                            {item.quantity != "null" ? <div> <span>Color: {item.quantity}</span></div> : null}
                                                            {item.thickness ? <div><span>Thickness: {item.thickness}</span></div> : null}
                                                            {item.size ? <div> <span>Size: {item.size}</span></div> : null}

                                                        </div>
                                                    </div>
                                                </div>

                                                <a type="button" className="deleteB" onClick={() => handleDelete(item)}> x </a>

                                            </div>

                                        </div>

                                    </div>
                                ))}
                            </div>





                        }
                        {noList ? null :
                            <div>
                                <button className="checkout d-flex justify-content-center" style={{}} onClick={handleShow}>Request for quote</button>
                                <Detail
                                    show={
                                        show
                                    }
                                    handleClose={
                                        handleClose
                                    }
                                    handleQ={
                                        handleQ
                                    }
                                    list={
                                        goodList
                                    }
                                />

                                <Quote
                                    userid={userid}

                                    handleCart={handleCart}
                                    q={
                                        q
                                    }
                                    hClose={
                                        hClose
                                    }

                                />
                            </div>
                        }
                    </div>
                </div>

            </div>

            <FooterThree position={fot} />
        </Fragment >
    );
};

export default Shopping;