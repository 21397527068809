import React from 'react';
import { Container, Row } from 'react-bootstrap'

const HeaderContainer = ({ children, classes }) => {

    return (
        <div className={`header-section section ${classes}`}>
            <Container fluid style={{ borderBottom: "solid", /* borderColor: '#1261B0' */ borderColor: 'black', borderWidth: '5px' }}>
                <Row className={'align-items-center'}>
                    {children}
                </Row>
            </Container>
        </div>
    );
};

export default HeaderContainer;
