import React, { Fragment, useState, useEffect } from 'react';
import Contact from "../../container/contact";
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import FooterThree from "../../components/footer/FooterThree";
import Notification from '../../components/notification/notification';
const ContactPage = ({ mg }) => {

    return (
        <Fragment>

            <Header classes={'position-static'} />
            <SideHeader mobile={true} />
            <Contact />
            <FooterThree position={'static'} />
        </Fragment>
    );
};

export default ContactPage;