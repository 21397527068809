import React, { Fragment, useState } from 'react';
import Contact from "../../container/contact";
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import FooterThree from "../../components/footer/FooterThree";
import backImage from '../../assets/images/hero/homepage1.jpg'
import { Link } from "react-router-dom";
import { userLogin } from '../../data/api';
import { createBrowserHistory } from "history";
import { notification } from "antd";
import { FrownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import "./log.scss"
const Login = () => {
    localStorage.clear();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("")
    const history = createBrowserHistory();
    const handleLogin = async () => {
        const data = await userLogin(
            userName,
            password
        );
        //  console.log(data.ret);
        if (data.ret === 200) {
            // console.log("success", data);

            localStorage.setItem("user", JSON.stringify([data.data.id, data.data.member_level, data.data.access_token]));
            history.push("/");
            window.location.reload();
        } else {
            notification.open({
                message: 'Log in failed!',
                description: 'Please enter a valid email or passward.',
                placement: 'btmRight',
                icon: <ExclamationCircleOutlined style={{ color: "red" }} />

            });

        }
    };
    return (
        <Fragment>
            <Header classes={'position-static'} />
            <SideHeader mobile={true} />
            <div className="bg-grey section" style={{ backgroundImage: `url(${backImage})` }}>

                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xl-5 col-lg-10 col-md-6 mb-5 mb-md-0'>
                            <div className='card contact-form-wrap contact-form-bg d-flex justify-content-center align-items-center'>
                                <h2 >Login</h2>

                                <div className=' rld-single-input d-flex justify-content-center align-items-center' >
                                    <input
                                        type='Username'
                                        placeholder='Username'
                                        onChange={(e) =>
                                            setUserName(e.target.value)
                                        }
                                        required
                                    />
                                </div>
                                {/* <div className='rld-single-input'>
									<input
										type='text'
										placeholder='Mobile'
										onChange={(e) =>
											setMobile(e.target.value)
										}
										required
									/>
								</div> */}
                                <div className='rld-single-input d-flex justify-content-center align-items-center'>
                                    <input
                                        type='password'
                                        placeholder='Password'
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }

                                    />
                                </div>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <button
                                        className='signB'
                                        onClick={handleLogin}

                                    >
                                        Sign in
                                    </button>
                                </div>
                                <span style={{ marginTop: '15px' }}>New to us? <Link to={`${process.env.PUBLIC_URL + "/register"}`} style={{ color: '#1261B0' }}>Ceate an account.</Link></span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <FooterThree position={'static'} />
        </Fragment>
    );
};

export default Login;