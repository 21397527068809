import React, { useState, useEffect, useContext } from 'react';
import { NavLink, Outlet } from "react-router-dom";
import navbarData from '../../data/navbar'
import { FiShoppingCart } from "react-icons/fi";
import { productC } from '../../data/api';
import { classInfo } from '../../data/api';
import { Link } from 'react-router-dom'
const NavItem = () => {
    const [pdList, setPdList] = useState([])
    const shopcart = JSON.parse(localStorage.getItem("shopCart"));
    const [pool, setPool] = useState("");

    useEffect(() => {

        const getPList = async () => {

            const data = await productC();

            setPdList(data.data)
        }; getPList();

        const getInfo = async () => {

            const data = await classInfo(6);

            setPool(data.data[0])
        }; getInfo();



    }, []);
    //  console.log(pool)

    return (

        navbarData.map(navItem => (
            <li key={navItem.id} className={navItem.subMenu ? 'menu-item-has-children' : null}>
                <NavLink
                    exact={navItem.link === `/`}
                    to={`${process.env.PUBLIC_URL + navItem.link}`}

                    activeClassName={'active'}>
                    <a>{navItem.title}</a>

                </NavLink>

                {navItem.list ? (
                    <ul className="sub-menu">
                        {pdList.map((item, index) => (
                            <li key={index}
                            >
                                <Link to={`/product/${item.id}`}>{item.name}</Link>

                            </li>
                        ))}
                    </ul>
                ) : null}

                {navItem.subMenu && navItem.id !== 3 ? (
                    <ul className="sub-menu">
                        {navItem.subMenu.map(subItem => (
                            <li key={subItem.id}
                                className={subItem.subMenu ? "menu-item-has-children" : null}>
                                <NavLink to={`${process.env.PUBLIC_URL + subItem.link}`}>{subItem.title}</NavLink>

                                {subItem.subMenu ? (
                                    <ul className="sub-menu">
                                        {subItem.subMenu.map(dropSubItem => (
                                            <li key={dropSubItem.id}>
                                                <NavLink to={`${process.env.PUBLIC_URL + dropSubItem.link}`}>
                                                    {dropSubItem.title}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        ))}
                    </ul>
                ) : null}

            </li>

        ))

    );
};

export default NavItem;
