import React, { useState } from 'react';
import { Container, Table } from 'react-bootstrap'
import ComDetail from './commentsD';
import EditCategory from './CategoryManage/editCategory';
import EditGoods from './GoodsManagement/editGoods';
import { DeleteCom, getComment } from "../../data/api";
import FavoriteDetail from './favoriteD';
import "./style.scss"
import { mGetFavorite } from '../../data/api';
import { DeleteFavorite } from '../../data/api';
const TableContent = ({ pdList, reload, cate, tableType, comm, order }) => {
    // console.log(order)
    const [cD, setCD] = useState(); //comment detail
    const [show, setShow] = useState(false);
    const [categoryD, setCategoryD] = useState();//category detail
    const [showE, setShowE] = useState(false);
    const [gD, setGD] = useState(); // goods detail
    const [showEG, setShowEG] = useState(false);
    const [showF, setShowF] = useState(false);//Favorite
    const [favoriteD, setFavoriteD] = useState();
    const [tempGoods, setTempGoods] = useState([]);
    const [tempGoodsName, setTempGoodsName] = useState([]);
    const tableList = pdList;
    const [orderList, setOrderList] = useState(order);
    const [commList, setCommList] = useState(comm);
    const cateList = cate;
    const tempUrl = [];
    const temp = [];
    const tempName = [];
    //comment
    const handleShow = (c) => {
        setCD(c)
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);

    };
    //all category
    const handleShowE = (c) => {
        setCategoryD(c)
        setShowE(true);
    };
    const handleCloseE = () => {
        setShowE(false);

    };

    const mGetF = async () => {

        const data = await mGetFavorite();
        // console.log("data", data)
        setOrderList(data.data)
    };
    //Favorite 
    const handleShowF = (c) => {
        // console.log("yes")
        setFavoriteD(c)
        setShowF(true);
    };
    const handleCloseF = () => {
        setShowF(false);

    };
    //goods
    const handleShowEG = (c) => {
        console.log(c)
        if (c.pic != null) {
            if (c.pic == "delete") {
                temp.push(" ")
            } else {
                temp.push(c.pic)
            }

        } else {
            temp.push(" ")
        }
        if (c.pic2 != null) {

            if (c.pic2 == "delete") {
                temp.push(" ")
            } else {
                temp.push(c.pic2)
            }
        } else {
            temp.push(" ")
        }
        if (c.pic3 != null) {

            if (c.pic3 == "delete") {
                temp.push(" ")
            } else {
                temp.push(c.pic3)
            }
        } else {
            temp.push(" ")
        }
        if (c.pic4 != null) {

            if (c.pic4 == "delete") {
                temp.push(" ")
            } else {
                temp.push(c.pic4)
            }
        } else {
            temp.push(" ")
        }
        if (c.pic5 != null) {

            if (c.pic5 == "delete") {
                temp.push(" ")
            } else {
                temp.push(c.pic5)
            }
        } else {
            temp.push(" ")
        }
        if (c.pic6 != null) {

            if (c.pic6 == "delete") {
                temp.push(" ")
            } else {
                temp.push(c.pic6)
            }
        } else {
            temp.push(" ")
        }
        if (c.pic7 != null) {

            if (c.pic7 == "delete") {
                temp.push(" ")
            } else {
                temp.push(c.pic7)
            }
        } else {
            temp.push(" ")
        }
        if (c.pic8 != null) {

            if (c.pic8 == "delete") {
                temp.push(" ")
            } else {
                temp.push(c.pic8)
            }
        } else {
            temp.push(" ")
        }
        if (c.pic9 != null) {

            if (c.pic9 == "delete") {
                temp.push(" ")
            } else {
                temp.push(c.pic9)
            }
        } else {
            temp.push(" ")
        }
        if (c.pic10 != null) {

            if (c.pic10 == "delete") {
                temp.push(" ")
            } else {
                temp.push(c.pic10)
            }
        } else {
            temp.push(" ")
        }

        setTempGoods([...temp])
        //picname
        if (c.picname1 != "null") {

            tempName.push(c.picname1)
        } else {
            tempName.push("")
        }
        if (c.picname2 != "null") {


            tempName.push(c.picname2)

        } else {
            tempName.push("")
        }
        if (c.picname3 != "null") {


            tempName.push(c.picname3)

        } else {
            tempName.push("")
        }
        if (c.picname4 != "null") {


            tempName.push(c.picname4)

        } else {
            tempName.push("")
        }
        if (c.picname5 != "null") {


            tempName.push(c.picname5)

        } else {
            tempName.push("")
        }
        if (c.picname6 != "null") {


            tempName.push(c.picname6)

        } else {
            tempName.push("")
        }
        if (c.picname7 != "null") {


            tempName.push(c.picname7)

        } else {
            tempName.push("")
        }
        if (c.picname8 != "null") {


            tempName.push(c.picname8)

        } else {
            tempName.push("")
        }
        if (c.picname9 != "null") {


            tempName.push(c.picname9)

        } else {
            tempName.push("")
        }
        if (c.picname10 != "null") {


            tempName.push(c.picname10)

        } else {
            tempName.push("")
        }
        setTempGoodsName([...tempName])
        setGD(c)
        setShowEG(true);
    };
    const handleCloseEG = () => {
        setShowEG(false);

    };

    const handleDeleteFavorite = async (id) => {

        const dCom = await DeleteFavorite(id);

        if (dCom.ret == 200) {
            mGetF();
            handleCloseF();

        }

    };
    const handleComment = async (id) => {

        const dCom = await DeleteCom(id);

        if (dCom.ret == 200) {
            comments();
            handleClose();

        }

    };

    const isSmall = window.innerWidth < 550;
    const comments = async () => {

        const data = await getComment();
        // console.log("data", data)
        setCommList(data.data)
    }
    //All categories
    const renderBody = (tableList, index) => {
        return (
            <tr key={index}>

                {/*  <td>{tableList.id}</td> */}
                <td style={{ whiteSpace: 'nowrap' }}>{tableList.name}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{tableList.timestamp.substring(0, 10)}</td>
                {/*  <td>{tableList.display}</td> */}
                <td>{tableList.hpdisplay}</td>
                <td style={{ textAlign: 'center' }}><img src={tableList.picurl} style={{ width: '50px', height: '50px' }}></img></td>
                <td><button className="editB" onClick={() => handleShowE(tableList)}>edit</button></td>
            </tr>
        )
    }
    //Comments
    const renderC = (commList, index) => {
        return (
            <tr key={index}>
                <td style={{ whiteSpace: 'nowrap' }}>{commList.firstname} {commList.lastname}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{commList.timestamp.substring(0, 10)}</td>
                {/*  <td>{commList.phone}</td> */}
                {/*  <td>{commList.email}</td> */}

                <td style={{ textAlign: 'left' }}>{isSmall ? commList.content.substring(0, 10) : commList.content.substring(0, 50)}...</td>
                <td><button className="editB" onClick={() => handleShow(commList)}>Details</button></td>

            </tr>
        )
    }
    //Favorite
    const renderF = (orderList, index) => {
        return (
            <tr key={index}>
                <td style={{ whiteSpace: 'nowrap' }}>{orderList.label}</td>
                {orderList.name == "" ? <td >{"-"}</td> : <td style={{ whiteSpace: 'nowrap' }}>{orderList.name}</td>}
                <td style={{ whiteSpace: 'nowrap' }}>{orderList.timestamp.substring(0, 10)}</td>
                {/*  <td>{orderList.phone}</td> */}
                {/*  <td>{orderList.email}</td> */}

                {orderList.email == "" ? <td >{"-"}</td> : <td style={{ textAlign: 'left' }}>{isSmall ? orderList.email.substring(0, 9) + "..." : orderList.email}</td>}
                <td><button className="editB" onClick={() => handleShowF(orderList)}>Details</button></td>

            </tr>
        )
    }
    //product list
    const renderCate = (cateList, index) => {
        // console.log("aaa", cateList)
        return (
            <tr key={index}>
                {/*  <td>{cateList.id}</td> */}
                <td style={{ whiteSpace: 'nowrap' }} >{cateList.tittle}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{cateList.timestamp.substring(0, 10)}</td>
                {/*  <td>{cateList.display}</td> */}
                <td><button className="editB" onClick={() => handleShowEG(cateList)}>edit</button></td>
            </tr>
        )
    }

    return (

        <Table className="pt-10 table-bordered table-hover table-dark" responsive="sm" responsive="md" responsive="lg" responsive="xl" size="sm">
            <caption>{tableType}</caption>
            <thead className="thead-primary" style={{ textAlign: 'center' }}>
                {tableType == "All Categories" ? <tr >

                    <th >Name</th>
                    <th>Date</th>
                    {/*  <th>Display</th> */}
                    <th>Home</th>
                    <th>Image</th>
                    <th>_</th>
                </tr> : null}

                {tableType == "comments" ? <tr >

                    <th>Name</th>
                    <th>Date</th>
                    {/* <th>Phone</th>
                    <th>Email</th> */}

                    <th>Message</th>
                    <th>_</th>
                </tr> : null}

                {tableType != "comments" && tableType != "All Categories" && tableType != "Favorite" ? <tr >
                    <th >Name</th>
                    <th>Date</th>
                    <th>_</th>
                </tr> : null}

                {tableType == "Favorite" ? <tr >
                    <th >Label</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Email</th>
                    <th>_</th>
                </tr> : null}


            </thead>
            {/* -----------Product Caregory---------- */}
            {tableType == "All Categories" ? <tbody className="body" >
                {tableList.map(renderBody)}
                {showE ? <EditCategory
                    showE={
                        showE
                    }
                    handleCloseE={
                        handleCloseE
                    }

                    category={
                        categoryD
                    } /> : null}
            </tbody> : null}
            {/*---------goods----------*/}
            {tableType != "comments" && tableType != "All Categories" && tableType != "Favorite" ? <tbody className="body">
                {cateList.map(renderCate)}
                {showEG ? <EditGoods
                    showEG={
                        showEG
                    }
                    reload={reload}
                    handleCloseEG={
                        handleCloseEG
                    }
                    tempName={tempGoodsName}
                    temp={
                        tempGoods
                    }
                    goodsList={
                        gD
                    } /> : null}
            </tbody> : null}
            {/* ----------Comments------------ */}
            {tableType == "comments" ? <tbody className="body">
                {commList.map(renderC)}
                {show ? <ComDetail
                    show={
                        show
                    }
                    handleClose={
                        handleClose
                    }
                    handleComment={handleComment}
                    detail={
                        cD
                    }
                /> : null}


            </tbody> : null}
            {/* ----------Favorite------------ */}
            {tableType == "Favorite" ? <tbody className="body">
                {orderList.map(renderF)}
                {showF ? <FavoriteDetail
                    showF={
                        showF
                    }
                    handleCloseF={
                        handleCloseF
                    }

                    handleDelete={
                        handleDeleteFavorite
                    }
                    mGetF={
                        mGetF
                    }
                    detail={
                        favoriteD
                    }
                /> : null}


            </tbody> : null}
        </Table>


    )
};
export default TableContent;