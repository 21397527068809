import React from 'react';

// File imported
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import BannerPersonalPortfolio from "../../components/HeroBanner/PersonalPortfolio";
import PersonalPortfolio from "../../container/portfolio/PersonalPortfolio";
import PersonalPortfolioService from "../../container/service";
import PersonalPortfolioTeam from "../../container/team";
import Contact from "../../container/contact";
import Footer from "../../components/footer/FooterThree";
import Notification from '../../components/notification/notification';

const HomePersonalPortfolio = () => {
    return (
        <div className={'main-wrapper p-0'}>
            {/* <Notification message={'Welcome! Our website is under construction at the moment. There are more functions to come. Please come to our Showroom for more information. '} /> */}
            <Header classes={'position-static'} />
            <SideHeader mobile={true} />
            <BannerPersonalPortfolio header="New website is coming..." />
            <PersonalPortfolio />
            {/* <PersonalPortfolioService /> */}
            {/*   <PersonalPortfolioTeam /> */}
            {/* <Contact /> */}
            <Footer position={'static'} />
        </div>
    );
};

export default HomePersonalPortfolio;
