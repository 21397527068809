import axios from "axios";
const baseURL = "https://shop.api.cyberate.com.au/api/";

//申请访问令牌
//App.Auth.ApplyToken
/* export var ApplyToken = async () => {
    let formData = new FormData();
    formData.append("app_key", "vdi2N6NuUMc6");
    formData.append("app_secret", "WrOHy4yrEar6Nwfd54zJMqY4mul06aup");

    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Auth.ApplyToken",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    console.log(result)
    return result;
}; */

//Submit contact Form
//App.Contactus.NewContactus
export var submitForm = async (
    firstName,
    lastName,
    email,
    phone,
    companyName,
    content
    // countrycode
) => {
    let formData = new FormData();
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("firstname", firstName);
    formData.append("lastname", lastName);
    formData.append("companyname", companyName);
    formData.append("content", content);
    formData.append("companyid", "1"); //1 is tile companyid

    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Contactus.NewContactus",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//Get comment
//App.Contactus.GetContactus
export var getComment = async (
    token,
) => {
    let formData = new FormData();
    formData.append("access_token", token); //1 is tile companyid
    formData.append("companyid", "1"); //1 is tile companyid

    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Contactus.GetContactus",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//normal user member level 90
//manager member level 201
//User Login
//App.Auth.UserLogin
export var userLogin = async (
    userName,
    password
    // countrycode
) => {
    let formData = new FormData();
    formData.append("username", userName);
    formData.append("password", password);
    formData.append("app_key", 'QEScSHoWM1MJd4F2d');


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Auth.UserLogin",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//now only check usename
//User Register
//App.User.Register
export var userRegister = async (
    userName,
    password,
    moblie,
    email,
    // countrycode
) => {
    let formData = new FormData();
    formData.append("username", userName);
    formData.append("password", password);
    formData.append("mobile", moblie);
    formData.append("email", email);
    formData.append("companyid", 1); //normal user is 2, manager id is 1


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.User.Register",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//Shopping Cart Details
//App.Shopcar.NewShopcar
export var shoppingCart = async (

    userid,
    companyid
    // countrycode
) => {
    let formData = new FormData();

    formData.append("userid", userid);
    formData.append("companyid", 1);


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Shopcar.NewShopcar",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    //console.log("newcar", result);
    return result;
};

//Add product to shopping cart
//App.Shopcargoods.NewShopcargoods
export var addProduct = async (
    goodsid,
    userid,
    size,
    thickness,
    colorN,

    shopcarid,
    title,
    selectedImg
    // countrycode
) => {

    let formData = new FormData();
    formData.append("userid", userid);
    formData.append("companyid", 1);
    formData.append("goodsid", goodsid);
    formData.append("size", size);
    formData.append("thickness", thickness);
    formData.append("quantity", colorN);
    formData.append("shopcarid", shopcarid);
    formData.append("tittle", title);
    formData.append("picurl", selectedImg);


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Shopcargoods.NewShopcargoods",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });

    // console.log("add", result)
    return result;
};

//Get the product in shopcar
//App.Shopcargoods.UsgetShopcargoods
export var getProduct = async (
    userid,
    carId
    // countrycode
) => {

    let formData = new FormData();
    formData.append("userid", userid);
    formData.append("shopcarid", carId);


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Shopcargoods.UsgetShopcargoods",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });

    //console.log("add", result)
    return result;
};

//Products categories and images
//App.Classes.CompanyidgetClasses
export var productC = async () => {
    let formData = new FormData();
    formData.append("companyid", 1);


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Classes.CompanyidgetClasses",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    // console.log(result)
    return result;
};
//all Product list  and images
//App.Goods.CompanyidgetGoods
export var productList = async () => {
    let formData = new FormData();
    formData.append("companyid", '1');


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Goods.CompanyidgetGoods",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//Get goods by goodsID
//App.Goods.IdgetGoods
export var goodsBY_id = async (id) => {

    let formData = new FormData();
    formData.append("id", id);


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Goods.IdgetGoods",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//Submit Favorite
//App.Favorite.NewFavorite
export var favorite = async (
    userid,
    username,
    email,
    phone,
    postcode,
    suburb,
    address,
    shopcarid,


) => {
    let formData = new FormData();
    formData.append("companyid", '1');
    formData.append("userid", userid);
    formData.append("name", username);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("postcode", postcode);
    formData.append("suburb", suburb);
    formData.append("address", address);
    formData.append("shopcarid", shopcarid);


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Favorite.NewFavorite",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//Use category find Product list  and images
//App.Goods.ClassidgetGoods
export var classGetProduct = async (

    classId,

) => {
    let formData = new FormData();
    formData.append("classid", classId);


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Goods.ClassidgetGoods",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//Get the category Info by id
//App.Classes.IdgetClasses
export var classInfo = async (

    classId,

) => {
    let formData = new FormData();
    formData.append("id", classId);


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Classes.IdgetClasses",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};
//
export var DeleteCarGoods = async (

    goodsid,
    shopcarid,

) => {
    let formData = new FormData();
    formData.append("goodsid", goodsid);
    formData.append("shopcarid", shopcarid);

    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=App.Shopcargoods.DeleteShopcargoods",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

/* -------------------Admin------------------ */

//All products categories
//Admin.Classes.CompanyidgetClasses
export var AdminP = async () => {
    let formData = new FormData();
    formData.append("companyid", 1);


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=Admin.Classes.CompanyidgetClasses",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//Delete comment
//Admin.Contactus.DeleteContactus
export var DeleteCom = async (

    commentId,

) => {
    let formData = new FormData();
    formData.append("id", commentId);


    let result = await axios({
        method: "post",
        url: baseURL + "app.php?s=Admin.Contactus.DeleteContactus",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

/* -------------------------product category---------------------- */
//Add new product category
//Admin.Classes.NewClasses
export var NewProduct = async (

    pName,
    home,
    pic,


) => {
    let formData = new FormData();
    formData.append("name", pName);
    formData.append("companyid", "1");
    formData.append("hpdisplay", home);
    formData.append("pic", pic);


    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Classes.NewClasses",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//Delete Category
//Admin.Classes.DeleteClasses
export var DeleteCategory = async (

    categoryId,

) => {
    let formData = new FormData();
    formData.append("id", categoryId);


    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Classes.DeleteClasses",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//Edit a category
//Admin.Classes.IdupClasses
export var EditC = async (
    categoryId,
    pName,
    home,
    compressedFile,


) => {
    let formData = new FormData();
    formData.append("id", categoryId);
    formData.append("name", pName);
    formData.append("companyid", "1");
    formData.append("hpdisplay", home);
    formData.append("pic", compressedFile);


    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Classes.IdupClasses",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

/* -------------------goods-------------------- */

//Add new goods 
//Admin.Admin.Goods.NewGoods
export var NewGoods = async (

    classId,
    pName,
    size,
    thickness,
    material,
    pic,
    pic2,
    pic3,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
    pic9,
    pic10,
    name,
    name1,
    name2,
    name3,
    name4,
    name5,
    name6,
    name7,
    name8,
    name9,

) => {
    let formData = new FormData();
    formData.append("classid", classId);
    formData.append("companyid", "1");
    formData.append("tittle", pName);
    formData.append("size", size);
    formData.append("thickness", thickness);
    formData.append("material", material);
    formData.append("pic", pic);
    formData.append("pic2", pic2);
    formData.append("pic3", pic3);
    formData.append("pic4", pic4);
    formData.append("pic5", pic5);
    formData.append("pic6", pic6);
    formData.append("pic7", pic7);
    formData.append("pic8", pic8);
    formData.append("pic9", pic9);
    formData.append("pic10", pic10);
    formData.append("picname1", name);
    formData.append("picname2", name1);
    formData.append("picname3", name2);
    formData.append("picname4", name3);
    formData.append("picname5", name4);
    formData.append("picname6", name5);
    formData.append("picname7", name6);
    formData.append("picname8", name7);
    formData.append("picname9", name8);
    formData.append("picname10", name9);

    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Goods.NewGoods",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};


//Edit goods
//Admin.Goods.IdupGoods
export var EditG = async (
    access_token,
    gId,
    classId,
    pName,
    size,
    thickness,
    material,
    pic,
    pic1,
    pic2,
    pic3,
    pic4,
    pic5,
    pic6,
    pic7,
    pic8,
    pic9,
    name,
    name1,
    name2,
    name3,
    name4,
    name5,
    name6,
    name7,
    name8,
    name9,


) => {

    let formData = new FormData();
    formData.append("access_token", access_token);
    formData.append("id", gId);
    formData.append("classid", classId);
    formData.append("companyid", "1");
    formData.append("tittle", pName);
    formData.append("size", size);
    formData.append("thickness", thickness);
    formData.append("material", material);
    formData.append("pic", pic);
    formData.append("pic2", pic1);
    formData.append("pic3", pic2);
    formData.append("pic4", pic3);
    formData.append("pic5", pic4);
    formData.append("pic6", pic5);
    formData.append("pic7", pic6);
    formData.append("pic8", pic7);
    formData.append("pic9", pic8);
    formData.append("pic10", pic9);
    if (name == "") {
        formData.append("picname1", "null");
    } else {
        formData.append("picname1", name);
    }
    if (name1 == "") {
        formData.append("picname2", "null");
    } else {
        formData.append("picname2", name1);
    }
    if (name2 == "") {
        formData.append("picname3", "null");
    } else {
        formData.append("picname3", name2);
    }
    if (name3 == "") {
        formData.append("picname4", "null");
    } else {
        formData.append("picname4", name3);
    }
    if (name4 == "") {
        formData.append("picname5", "null");
    } else {
        formData.append("picname5", name4);
    }
    if (name5 == "") {
        formData.append("picname6", "null");
    } else {
        formData.append("picname6", name5);
    }
    if (name6 == "") {
        formData.append("picname7", "null");
    } else {
        formData.append("picname7", name6);
    }
    if (name7 == "") {
        formData.append("picname8", "null");
    } else {
        formData.append("picname8", name7);
    }
    if (name8 == "") {
        formData.append("picname9", "null");
    } else {
        formData.append("picname9", name8);
    }
    if (name9 == "") {
        formData.append("picname10", "null");
    } else {
        formData.append("picname10", name9);
    }





    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Goods.IdupGoods",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    //console.log(result)
    return result;
};

//Edit image
//Admin.Goods.IdupGoodspic
export var ImageDelete = async (
    access_token,
    gId,
    index,


) => {

    let formData = new FormData();
    formData.append("access_token", access_token);
    formData.append("id", gId);

    if (index == 1) {
        formData.append("pic1", "delete");
    }
    if (index == 2) {
        formData.append("pic2", "delete");
    }
    if (index == 3) {
        formData.append("pic3", "delete");
    }
    if (index == 4) {
        formData.append("pic4", "delete");
    }
    if (index == 5) {
        formData.append("pic5", "delete");
    }
    if (index == 6) {
        formData.append("pic6", "delete");
    }
    if (index == 7) {
        formData.append("pic7", "delete");
    }
    if (index == 8) {
        formData.append("pic8", "delete");
    }
    if (index == 9) {
        formData.append("pic9", "delete");
    } if (index == 10) {
        formData.append("pic10", "delete");
    }


    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Goods.IdupGoodspic",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    //console.log(result)
    return result;
};

//Delete Goods
//Admin.Goods.DeleteGoods
export var DeleteGoods = async (

    classid,
    id,

) => {
    let formData = new FormData();
    formData.append("classid", classid);
    formData.append("id", id);


    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Goods.DeleteGoods",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};


/* --------------------Favorite-------------------- */
//Manager check order
//Admin.Favorite.GetFavorite
export var mGetFavorite = async () => {
    let formData = new FormData();

    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Favorite.GetFavorite",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};


//Handle Label
//Admin.Favorite.IdupFavoritelabel
export var changeLabel = async (
    id,
    label,
) => {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("label", label);
    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Favorite.IdupFavoritelabel",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

export var ShopHistoryUser = async (

    userid,


) => {
    let formData = new FormData();
    formData.append("userid", userid);


    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Favorite.UseridgetFavorite",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};

//Delete Favorite
//Admin.Favorite.DeleteFavorite
export var DeleteFavorite = async (


    id,

) => {
    let formData = new FormData();

    formData.append("id", id);


    let result = await axios({
        method: "post",
        url: baseURL + "admin.php?s=Admin.Favorite.DeleteFavorite",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) { });
    return result;
};
