import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
// Logo
import LogoDark from '../../assets/images/logo.png'
import LogoLight from '../../assets/images/light-logo.png'

import LogoSide from '../../assets/images/headerlogo.PNG'
import LogoTiles from '../../assets/images/logoF.png'

const Logo = ({ sideHeader }) => {
    // console.log("sider", sideHeader);
    return (
        <Fragment>
            {sideHeader ? (
                <Link to={`${process.env.PUBLIC_URL + '/'}`}>
                    <img src={LogoSide} alt="logo" />

                </Link>
            ) : (
                <div className="header-logo col-lg-3 col-6 mt-30 mb-30">
                    <Link to={`${process.env.PUBLIC_URL + '/'}`}>
                        <img src={LogoTiles} alt="logo" />
                    </Link>

                </div>
            )}
        </Fragment>
    );
};

export default Logo;
