import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap'
import PageContainer from '../../container/CustomPage';
import SidebarWrap from "../../container/sidebar";
import Sidebar from '../../components/sidebar';
import { AdminP, getComment } from '../../data/api';
import { classGetProduct } from '../../data/api';
import TableContent from './table';
import Compressor from 'compressorjs';
import { mGetFavorite } from '../../data/api';
//product Category
import AddNewC from './CategoryManage/category';
//Goods
import AddNewG from './GoodsManagement/Addgoods';

const ManagementBody = () => {
    const [tableType, setTableType] = useState("All Categories")
    const [pdList, setPdList] = useState([]);
    const [order, setOrder] = useState([]);
    const [category, setCategory] = useState([])
    const [allCom, setAllCom] = useState([]);
    const [add, setAdd] = useState(false);
    const [home, setHome] = useState(null);
    const [compressedFile, setCompressedFile] = useState(null);
    const [classId, setClassId] = useState(null);
    const [renew, setRenew] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {

        const getPList = async () => {
            var p = [];
            const data = await AdminP();
            //  console.log("data", data.data)

            for (let i = data.data.length - 1; i >= 0; i--) {

                p.push(data.data[i])

            }
            setPdList(p)
        }; getPList();


        const comments = async () => {

            const data = await getComment(user[2]);
            // console.log("data", data)
            setAllCom(data.data)
        }
        comments();

        const mGetF = async () => {

            const data = await mGetFavorite();
            // console.log("data", data)
            setOrder(data.data)
        }
        mGetF();
    }, []);

    const handleType = (t) => {
        setTableType(t);
    };

    //use class id get product list
    const handleCategory = async (id, name) => {
        setClassId(id);
        const data = await classGetProduct(id);

        if (data.ret == 200) {
            setCategory(data.data)

        } else {
            setCategory([])
        }

        setTableType(name);
    };

    const handleAdd = () => setAdd(true);
    const handleClose = () => {
        setHome(null);
        setCompressedFile(null);
        setAdd(false);
        setRenew(true)

    }
    const handleHome = (e) => {
        setHome(e.value)
    }
    //Reduce 20% of the image size
    const handleImage = (e) => {

        const image = e.target.files[0];
        new Compressor(image, {
            quality: 0.8,
            success: (compressedResult) => {
                const compressFile = new File([compressedResult], image.name, { type: compressedResult.type, });
                setCompressedFile(compressFile)
            },
        });
    };

    /*   const handleMultiImage = (e) => {
          const imInfo = [];
          const imUrl = [];
          const image = e.target.files;
  
          if (goodsImage.length < 10) {
              for (let i = 0; i < image.length; i++) {
  
                  if ((imUrl.length + imageUrl.length) < 10) {
                      const url = URL.createObjectURL(image[i]);
                      imUrl.push(url);
                      imInfo.push(image[i])
                  } else {
                      break;
                  }
  
              }
              setImageUrl([...imageUrl, ...imUrl]);
              setGoodsImage([...goodsImage, ...imInfo]);
          }
      } */
    /* const deleteImage = (e) => {

        const removeUrl = imageUrl.filter((item, index) => index !== e);
        const removeInfo = goodsImage.filter((item, index) => index !== e);
        setImageUrl(removeUrl);
        setGoodsImage(removeInfo);

    }; */
    return (
        <PageContainer classes={'bg-grey'}>
            <Container fluid >
                <div style={{ marginBottom: '30px' }}><h1>Management</h1></div>
                <Row>
                    <Col xl={9} lg={8} className={'mb-50 order-1 order-lg-2'} /* style={{ backgroundColor: 'lightblue', padding: '20px' }} */>

                        <div className="row justify-content-between">
                            <div className="col-9 sidebar-search" style={{ marginBottom: '10px' }}>
                                <form action="/" method="post">
                                    <input type="text" placeholder="Search" />
                                    <button><i className="fa fa-search" /></button>
                                </form>
                            </div>

                            {tableType != "comments" && tableType != "Favorite" ? <button className=" addButton col-2" onClick={handleAdd}><span>New</span></button> : null}
                            {/* new category */}
                            {tableType == "All Categories" ? <AddNewC show={add} home={home} handleHome={handleHome} handleClose={handleClose} handleImage={handleImage} compressedFile={compressedFile} /> : null}
                            {tableType != "All Categories" && tableType != "comments" && tableType != "Favorite" ? <AddNewG classId={classId} renew={renew} reload={handleCategory} /* deleteImage={deleteImage} handleMultiImage={handleMultiImage} imageUrl={imageUrl} goodsImage={goodsImage} */ tableType={tableType} show={add} handleClose={handleClose} /> : null}
                        </div>


                        {pdList.length > 0 ? <TableContent reload={handleCategory} pdList={pdList} cate={category} tableType={tableType} comm={allCom} order={order} /> : null}

                    </Col>

                    <Col xl={2} lg={3} className={'order-2 order-lg-1 mr-40'}>


                        <Sidebar title={"Product"}>

                            <ul className="sidebar-list" style={{}}>

                                <li><a onClick={() => handleType("All Categories")}>All categories</a></li>
                                {pdList.map((item, index) => (
                                    <li key={index}>
                                        <a onClick={() => handleCategory(item.id, item.name)}>{item.name}</a>
                                    </li>

                                ))}
                            </ul>
                        </Sidebar>

                        <Sidebar title={"Other"}>

                            <ul className="sidebar-list" style={{}}>

                                <li><a onClick={() => handleType("Favorite")}>Order</a></li>
                                <li><a onClick={() => handleType("comments")}>Comments</a></li>
                            </ul>
                        </Sidebar>
                    </Col>

                </Row>
            </Container>
        </PageContainer>
    );
};

export default ManagementBody;