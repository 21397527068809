import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from 'react-select';
import React, { Component, useEffect, useState } from "react";
import backImage from '../../../assets/images/hero/homepage1.jpg'
import { ModalTitle } from "react-bootstrap";
import './categoryM.scss';
import Compressor from 'compressorjs';
import { EditC, DeleteCategory } from "../../../data/api";

const EditCategory = ({ category, showE, handleCloseE }) => {
    const [compressedFile, setCompressedFile] = useState(null);
    const [pic, setPic] = useState(category.picurl);
    const [homeShow, setHomeShow] = useState(category.hpdisplay);
    const [pName, setPName] = useState(category.name);
    const categoryId = category.id

    // console.log(category)
    //Reduce 20% of the image size
    const handleImage = (e) => {

        const image = e.target.files[0];
        new Compressor(image, {
            quality: 0.8,
            success: (compressedResult) => {
                const compressFile = new File([compressedResult], image.name, { type: compressedResult.type, });
                setCompressedFile(compressFile)
                setPic(URL.createObjectURL(compressFile))
            },
        });
    };

    const handleSave = async () => {


        const submit = await EditC(
            categoryId,
            pName,
            homeShow,
            compressedFile,
        )
        handleCloseE();
        window.location.reload()

    };

    const handleDelete = async () => {

        const dCom = await DeleteCategory(categoryId);

        if (dCom.ret == 200) {

            handleCloseE();
            window.location.reload();
        }

    };
    return (
        <Modal show={showE} onHide={handleCloseE} size="md"   >

            <Modal.Header closeButton>

                <ModalTitle>{category.name}</ModalTitle>

            </Modal.Header>
            <Modal.Body className=' d-flex col justify-content-center' style={{ backgroundImage: `url(${backImage})`, objectFit: "cover", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className='contact-form-wrap row  pr-20  pb-30 pt-30 w-100' style={{ width: '90%', backgroundColor: 'white' }}>



                    <div className='col-12 justify-content-between'>

                        {/* -----------------Name----------------- */}
                        <div className='rld-single-input'>
                            <div className="d-flex justify-content-between">
                                <label htmlFor='title'>Name:</label>
                                <button className="delete" onClick={() => handleDelete()}>Delete</button>
                            </div>

                            <input
                                className='w-100'
                                name='title'
                                type='text'
                                value={category.name}
                                onChange={(e) =>
                                    setPName(e.target.value)
                                }
                                required
                            />
                        </div>
                        {/* -----------Home Show------------- */}

                        <div className="mt-15">
                            <label >Home Show:</label>
                            <div>
                                <input type="radio" value="1" onChange={(e) => setHomeShow(e.target.value)} checked={homeShow == 1} />
                                <span>Yes</span>
                            </div>
                            <div>
                                <input type="radio" value="0" onChange={(e) => setHomeShow(e.target.value)} checked={homeShow == 0} />
                                <span>No</span>
                            </div>
                        </div>
                    </div>
                    {/* ----------pic------------- */}
                    <div className='col-12 rld-single-input'  >
                        <label
                            htmlFor='pic'
                            className='text-black font-w600'
                            id='pic'
                        >
                            {/* Upload Picture */}
                            Upload Picture
                        </label>
                        <img style={{ padding: '10px' }} src={pic} />
                        <div className='custom-file'>
                            <input
                                type='file'
                                data-show-preview='false'
                                className='custom-file-input'
                                id='upimage'
                                onChange={(e) => handleImage(e)
                                }
                            // aria-describedby='customFile'

                            />

                            <label
                                className='custom-file-label'
                                htmlFor='customFile'
                            >
                                {compressedFile != null ? <span>{compressedFile.name}</span> : <span> Choose file</span>}

                            </label>

                        </div>
                    </div>
                </div>


            </Modal.Body>
            <Modal.Footer className="justify-content-center">

                <button className="SAVE" onClick={() => handleSave()}>Save</button>


            </Modal.Footer>
        </Modal>
    );
};
export default EditCategory;
